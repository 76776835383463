import React from 'react';
import {Container, Row, Col,Modal,Button,Form  } from 'react-bootstrap';
export default function RegisterModal(){
    return(
        <>
        <Container className='register login-logo d-flex justify-content-center align-items-center' style={{width:"100%",height:"100%" }}>
        <div className="modal show" style={{ display: 'block', position: 'initial'}}>
      <Modal.Dialog style={{maxWidth:"600px"}} className='custom-modal'>
        <Modal.Header closeButton>
            </Modal.Header>
        <Modal.Body>
          <Row>
          <Col lg={6} md={12}>
            <div className='registerForm' style={{backgroundColor:"white"}}>
                <Container style={{display:"grid"}} className=''>
                <Row className='login-logo d-flex justify-content-center align-items-center'>
                    <Col xs={6} md={6} lg={6}>
                    <img src={require("../assets/images/Nutsby_logo_black.png")} alt="" />
                    </Col>                    
                </Row>
                <Row>
                <Col sm={12} md={12}>
                    <Form>
                    <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Name</Form.Label>
                            <Form.Control className="custom-placeholder" type="textarea" placeholder="Enter your name" style={{backgroundColor:"rgb(244, 244, 244)"}}/>                            
                        </Form.Group>  

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control className="custom-placeholder" type="email" placeholder="Enter your e-mail id" maxLines={1} pattern={'/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;'} style={{backgroundColor:"rgb(244, 244, 244)"}}/>                            
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPhone">
                            <Form.Label>Phone No.</Form.Label>
                            <Form.Control className="custom-placeholder" type="tel" placeholder="Enter your phone no." maxLength={10} pattern={'[7-9]{1}[0-9]{9}'} style={{backgroundColor:"rgb(244, 244, 244)"}}/>                            
                        </Form.Group>

                        

                        <Form.Group className='d-flex justify-content-between align-items-center'>
                        
                        <Button variant="primary" type="submit" style={{borderColor:"orange",backgroundColor:"orange",color:"black",width:"100%"}} align="end">
                            Register
                        </Button>
                        </Form.Group>
                    </Form>
                </Col>
                </Row>
                </Container>

            </div>
            </Col>
            <Col lg={6} md={6} className="">
                <div className='imageContainer d-none d-lg-block' style={{width:"100%",overflow:"hidden",borderRadius:"0px 27px 27px 0px"}}>
                <img src={require("../assets/images/LoginGroup.png")} alt="" />
                </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer />
      </Modal.Dialog>
    </div>
        </Container>
        
        </>

    );
}