 
import { Container, Row, Col, Button, Breadcrumb } from 'react-bootstrap';
import { NavLink,Link, useParams } from 'react-router-dom';
// import Overlay from 'react-bootstrap/Overlay';
import React,{ useState,useEffect } from 'react';
import { age_8_17DataApi,age_18_27DataApi,age_28_37DataApi,age_38_50DataApi,age_50_aboveDataApi,AddToCart, NotifyMeApi, GetHeaderDetailsApi } from '../Service/ApiCalling';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; 
import SubscribeForm from "../MainComponents/SubscribeForm";
import DownloadTheApp from "../MainComponents/DownloadTheApp";
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import '../fonts/Inter-Regular.ttf'; 
import { DiscountIcon, EliteIcon } from "../MiniComponents/SvgIcons";
import ProductItemCard from '../MainComponents/SubComponents/MiniComponents/ProductItemCard';
import { useCart } from '../MainComponents/SubComponents/MiniComponents/CartContext';
import "../SubCss/goals.css";
import Slider from 'react-slick';
import { ShimmerContentBlock, ShimmerDiv } from 'shimmer-effects-react';
import MetaTags from '../MainComponents/Metatags';
export default function Agepage() {
  const shimmeritems = Array.from({ length: 6 });
  const shimmeritems1 = Array.from({ length: 2 });
  const ess_shimmeritems = Array.from({ length: 4 })
  const ess_shimmeritems1 = Array.from({ length: 3 })
  const main_shimmeritems = Array.from({ length: 2 });
  
    const [essential,setessential] =useState([]);
    const [must_buy,setmustbuy] =useState([]);
    const [sliders, setsliders] = useState([]);
    const [top_grossing, settop_grossing] = useState([]);
    const [is_subscribed,setIsSubscribed] = useState('');
    const [main_goal,setmaingoal] =useState([])
    const [sub_goal,setsubgoal] =useState([])
    const { setCartCount } = useCart();
    const [addClicked,setAddClicked] = useState(null);
    const [notifyClicked, setNotifyClicked] = useState(null);
    const [productsLoading,setLoading] = useState(true);

    const params = useParams();
    const [keyWordTag, setseKeywordtag] = useState([]);
    const [title_tag,setTitleTag] = useState("");
    const [meta_description_tag,setMetaDescriptionTag] = useState("");
    const [canonical_tag,setCanonicalTag] = useState("");
    const [og_title,setOGTitle] = useState("");
    const [og_url,setOGURL] = useState("");
    const [og_meta_description,setOGMetaDescription] = useState("");
    const [og_image,setOgImage] = useState("");
    const [alttagnames,setAltTagNames] = useState([]);
    const [altTags,setAltTags] = useState([]);
    const [banner,setbanner]= useState("");
    const [tag,setTag] = useState("");
    const [h1,setH1] = useState([]);
    const [h2,setH2] = useState([]);
    const [h3,setH3] = useState([]);
    const [alttext,setAltText] = useState("");

    const fetchInfo=async ()=> {  
        if(params.id==="1"){
            let age_8_17DataApiData = await age_8_17DataApi();
            if (age_8_17DataApiData.error==="0") {  
                setessential(age_8_17DataApiData.cards)
                setsliders(age_8_17DataApiData.sliders);
                settop_grossing(age_8_17DataApiData.top_grossing);
                setmaingoal(age_8_17DataApiData.sub_main_goals);
                console.log(main_goal,"main_goals");
                setsubgoal(age_8_17DataApiData.sub_sub_goals);
                setmustbuy(age_8_17DataApiData.must_buy_supplements_cards);
                setLoading(false);
                setTitleTag("Top-Rated Hair Care Products for Perfect Hair | Nutsby");
                setMetaDescriptionTag("Explore a wide range of premium hair care products to keep your hair healthy, shiny, and strong. Shop top-rated solutions for all hair types at Nutsby.");
                setseKeywordtag(["Hair Care Products","Healthy Hair Solutions","Hair Strength and Shine","Hair Care Essentials","Best Hair Products"]);
                setCanonicalTag("https://www.nutsby.com/hair-care-supplements");
                setAltText("black haired men promoting hair care supplements");
                setOGTitle("Premium Hair Care Products for Healthier Hair");
                setOGURL("https://www.nutsby.com/hair-care");
                setOGMetaDescription("Find premium hair care products that ensure healthy, shiny, and strong hair. Shop top solutions tailored for every hair type at Nutsby.");
                setOgImage("https://www.nutsby.com/banner/hair-care.jpg");
            }else{
              setLoading(false);
            }
        }

        else if(params.id==="2"){
            let age_18_27DataApiData = await age_18_27DataApi();
            if (age_18_27DataApiData.error==="0") {
                setessential(age_18_27DataApiData.cards);
                setsliders(age_18_27DataApiData.sliders);
                settop_grossing(age_18_27DataApiData.top_grossing);
                setmaingoal(age_18_27DataApiData.sub_main_goals);
                setsubgoal(age_18_27DataApiData.sub_sub_goals);
                setmustbuy(age_18_27DataApiData.must_buy_supplements_cards);
                setLoading(false);
                setTitleTag("");
                setMetaDescriptionTag("");
                setseKeywordtag([]);
                setCanonicalTag("");
                setAltText("");
                setOGTitle("");
                setOGURL("");
                setOGMetaDescription("");
                setOgImage("");
            }else{
              setLoading(false);
            }
        }
        
        else if(params.id==="3"){
           
        let age_28_37DataApiData = await age_28_37DataApi();
        if (age_28_37DataApiData.error==="0") {
            
            setessential(age_28_37DataApiData.cards);
            setsliders(age_28_37DataApiData.sliders);
            settop_grossing(age_28_37DataApiData.top_grossing);
            setmaingoal(age_28_37DataApiData.sub_main_goals);
            setsubgoal(age_28_37DataApiData.sub_sub_goals);
            setmustbuy(age_28_37DataApiData.must_buy_supplements_cards);
            setLoading(false);
            setTitleTag("");
                setMetaDescriptionTag("");
                setseKeywordtag([]);
                setCanonicalTag("");
                setAltText("");
                setOGTitle("");
                setOGURL("");
                setOGMetaDescription("");
                setOgImage("");
        }else{
          setLoading(false);
        }
    }

    else if(params.id==="4"){
      let age_38_50DataApiData = await age_38_50DataApi();
      if (age_38_50DataApiData.error==="0") {
          setessential(age_38_50DataApiData.cards)
          setsliders(age_38_50DataApiData.sliders);
          settop_grossing(age_38_50DataApiData.top_grossing);
          setmaingoal(age_38_50DataApiData.sub_main_goals);
          setsubgoal(age_38_50DataApiData.sub_sub_goals);
          setmustbuy(age_38_50DataApiData.must_buy_supplements_cards);
          setLoading(false);
          setTitleTag("");
                setMetaDescriptionTag("");
                setseKeywordtag([]);
                setCanonicalTag("");
                setAltText("");
                setOGTitle("");
                setOGURL("");
                setOGMetaDescription("");
                setOgImage("");
      }else{
        setLoading(false);
      }
    }
    else if(params.id==="5"){
      let age_50_aboveDataApiData = await age_50_aboveDataApi();
      if (age_50_aboveDataApiData.error==="0") {
          setessential(age_50_aboveDataApiData.cards)
          setsliders(age_50_aboveDataApiData.sliders);
          settop_grossing(age_50_aboveDataApiData.top_grossing);
          setmaingoal(age_50_aboveDataApiData.sub_main_goals);
          setsubgoal(age_50_aboveDataApiData.sub_sub_goals);
          setmustbuy(age_50_aboveDataApiData.must_buy_supplements_cards);
          setLoading(false);
          setTitleTag("");
                setMetaDescriptionTag("");
                setseKeywordtag([]);
                setCanonicalTag("");
                setAltText("");
                setOGTitle("");
                setOGURL("");
                setOGMetaDescription("");
                setOgImage("");
      }else{
        setLoading(false);
      }
    }
    
    }

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024, // Medium to large screens
          settings: {
            slidesToShow: 4, // Ensure 6 slides are shown even after 1024px
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600, // Small to medium screens
          settings: {
            slidesToShow: 2, // Show 5 slides on smaller screens
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480, // Very small screens
          settings: {
            slidesToShow: 2, // Show 3 slides on very small screens
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 300, // Tiny screens
          settings: {
            slidesToShow: 2, // Show 2 slides on tiny screens
            slidesToScroll: 1,
          },
        },
      ],
    };
    const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      appendDots: (dots) => (
        <div
          style={{
            position: 'relative',
            bottom: '-5px', // Adjust this to move the dots closer to the slider
            display: 'flex',
            justifyContent: 'center',
            paddingLeft:'0px'
          }}
        >
          <ul style={{ margin: '0px' }}>{dots}</ul>
        </div>
      ),
    };

const AddToCartCall=async (p_id,qnt,clicked,index,max_qty)=> { 
    // AddToCart 
    
    if(clicked == true){
      setAddClicked(index);
    }
    // if(qnt>0)
    //     {
    
    if(Number(max_qty)>Number(qnt)){

    
            let addcartDatatemp = await AddToCart(p_id,qnt);
            if (addcartDatatemp.error === "0") {
                // fetchInfo();  
                    const newList = top_grossing.map((item) => {
                        if (item.product_id === p_id) {
                          const updatedItem = {
                            ...item, 
                            cart_quantity: addcartDatatemp.cart_quantity,
  
                          };
                          setTimeout(function(){
                            setAddClicked(null);
                          },1300);
                  
                          return updatedItem;
                        } 
                        return item;
                      });
                  
                      settop_grossing(newList); 
                    // setcartCount(addcartDatatemp.items_count);
  
                    // const newupdatedItem = {
                    //     ...cartCalculationData,
                    //     items_total: addcartDatatemp.cart_price,
                    //     total_price: addcartDatatemp.grand_total,
                    //     total_price: addcartDatatemp.grand_total_raw,
  
                    //   };
                    //   setcartCalculationData(newupdatedItem);
                // (e) =>setdetailsData((prevState) => ({ ...prevState, [e.target.name]: 1 }));
                // this.setState( detailsData.name="AKash");
          
              const headerData = await GetHeaderDetailsApi();
              if (headerData.error === "0") {
                if(headerData.cart_count!=null){
                  setCartCount(headerData.cart_count);
                }else{
                  setCartCount(0);
                }
                
                console.log(headerData.cart_count,"khfaosdufhuafs") 
              }
            }else{
              // alert(addcartDatatemp.error);
            }
          }else{

          }   
   
  };

  const NotifyMeCall = async (p_id, type,clicked,index) => {
    if(clicked === true){
      setNotifyClicked(index);
    }
    try {
      let Data = await NotifyMeApi(p_id, type);
      if (Data.error === "0") {
        if (type === "stop") {
          Data.is_notified = "0";
          console.log("is_stopped");
        } else {
          Data.is_notified = "1";
          console.log("is_notified");
        }
        const newList = top_grossing.map((item) => {
          if (item.product_id === p_id) {
            const updatedItem = {
              ...item,
              is_notified: Data.is_notified,
            };
            setTimeout(function(){
              setNotifyClicked(null);
            },1100)
            return updatedItem;
          }
          return item;
        });
        settop_grossing(newList);
      } else {
        console.log(Data.error);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

useEffect(() => {

  setIsSubscribed(localStorage.getItem("is_subscribed"));
  
  console.log("id passed",params);
    fetchInfo(); 
  }, []);


const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  
  const productsresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    minidesktop: {
      breakpoint: { max: 1495, min: 1200 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1200, min: 900 },
    items: 4,
  },
  minitablet: {
    breakpoint: { max: 900, min: 464 },
  items: 3,
},
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3, 
  },
}; 

  const productsresponsivesss = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2, 
    },
  };


   
  const imageMap = {
    "1": require("../assets/images/PNG/age_1.png"),
    "2": require("../assets/images/PNG/age_2.png"),
    "3": require("../assets/images/PNG/age_3.png"),
    "4": require("../assets/images/PNG/age_4.png"),
    "5": require("../assets/images/PNG/age_5.png"),    
  };
  const selectedImage = imageMap[params.id];

  const imageMap2 = {
    "1": require("../assets/images/age1_banner_mobile.png"),
    "2": require("../assets/images/age2_banner_mobile.png"),
    "3": require("../assets/images/age3_banner_mobile.png"),
    "4": require("../assets/images/age4_banner_mobile.png"),
    "5": require("../assets/images/age5_banner_mobile.png"),    
  };
  const selectedImage2 = imageMap2[params.id];



  const breadcrumbMap = {
    "1": "Age 8-17",
    "2": "Age 18-27",
    "3": "Age 28-37",
    "4": "Age 38-50",
    "5": "Age 50+",    
  };
  
  const selectedBreadcrumb = breadcrumbMap[params.id];



  
  if(!productsLoading){
  return (
    <>     
    <MetaTags
      
      title={title_tag}
      description={meta_description_tag}
      keywords={keyWordTag}
      canonical={canonical_tag}
      url="https://www.nutsby.com"
      image="https://www.nutsby.com/images/logo.jpg" 
      alt={alttext}
      ogTitle={og_title}
      ogUrl={og_url}
      ogDescription={og_meta_description}
      ogImage={og_image}
      schema={{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Nutsby Fitcare LLP",
        "url": "https://www.nutsby.com",
        "logo": "https://www.nutsby.co/logo.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91-1234567890",
          "contactType": "Customer Service",
        },
      }}
    />
{
    <div> 
         {/* <section>
          <div onClick={()=>{}} >
            <div className="mainCarousel d-none d-md-block d-lg-block " style={{paddingBottom: "20px",paddingTop: "8rem"}} > 
            <Row style={{display:'flex',justifyContent:'center'}}>
                  <Col lg={12} md={12} sm={12} col={12} >

                  {(params.id === "1") ? <img src={require("../assets/images/PNG/8-17banner.png")} alt="" /> :
                   (params.id === "2") ? <img src={require("../assets/images/PNG/18-27banner.png")} alt="" /> :
                   (params.id === "3") ? <img src={require("../assets/images/PNG/28-37banner.png")} alt="" /> :
                   (params.id === "4") ? <img src={require("../assets/images/PNG/38-50banner.png")} alt="" /> :
                   (params.id === "5") ? <img src={require("../assets/images/PNG/50+banner.png")} alt="" /> :
                    ""}

                          
                    </Col>                    
                </Row> 
            </div>
            <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block" style={{paddingBottom: "20px",paddingTop: "3rem"}} > 
                <Row>
                <Col lg={12} md={12} sm={12} col={12}>
                {(params.id === "1") ? <img src={require("../assets/images/PNG/8-17banner.png")} alt="" /> :
                   (params.id === "2") ? <img src={require("../assets/images/PNG/18-27banner.png")} alt="" /> :
                   (params.id === "3") ? <img src={require("../assets/images/PNG/28-37banner.png")} alt="" /> :
                   (params.id === "4") ? <img src={require("../assets/images/PNG/38-50banner.png")} alt="" /> :
                   (params.id === "5") ? <img src={require("../assets/images/PNG/50+banner.png")} alt="" /> :
                    ""}
                    </Col>                      
                </Row> 
            </div>  
          </div>
        </section>   */}

          <section >
            <div onClick={()=>{}} >
              <div className="mainCarousel d-none d-md-block d-lg-block container" style={{paddingBottom: "0px",paddingTop: "10rem",borderRadius:'30px'}} > 
              <Row >
                    <Col lg={12} md={12} sm={12} col={12}  >
                      
                          
                          {selectedImage ? (
                            <img src={selectedImage} alt="" style={{ borderRadius: '20px',webkitUserDrag:"none" }} />
                          ) : <></>}
                            
                      </Col>                    
                  </Row> 
              </div>
              <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block container" style={{paddingBottom: "0px",paddingTop: "5em"}} > 
                  <Row>
                  <Col lg={12} md={12} sm={12} col={12}>
                  
                  {selectedImage2 ? (
                            <img src={selectedImage2} alt="" style={{ borderRadius: '18px',webkitUserDrag:"none" }} />
                          ) : <></>}
                        
                      </Col>                      
                  </Row> 
              </div>  
             
             <Row className='d-block d-md-none d-lg-none d-sm-none'>
              <Col xs={12} sm={12} md={8} lg={8} className='breadcrumb' style={{marginBottom:"0px"}}>
                 
                   <Breadcrumb>
                    <Breadcrumb.Item role='button'
                    
                    onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}> Home </Breadcrumb.Item>
                    {selectedBreadcrumb ? (
                      <Breadcrumb.Item href="#"> {selectedBreadcrumb} </Breadcrumb.Item>
                    ) : <></>}
                  </Breadcrumb>
                </Col>
                </Row> 

                <div className='d-none d-md-block d-lg-block d-sm-block container'>
              <Col  className='breadcrumb' style={{marginBottom:"0px"}}>
              <Breadcrumb>
                    <Breadcrumb.Item role='button'
                    
                    onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}> Home </Breadcrumb.Item>
                    {selectedBreadcrumb ? (
                      <Breadcrumb.Item href="#"> {selectedBreadcrumb} </Breadcrumb.Item>
                    ) : <></>}
                  </Breadcrumb>
                </Col>
                </div> 
                </div>
                
           

          </section>  

  {/*<!--second section --->*/}
  <section className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" ,marginBottom:'20px'}}>
  {/* Heading */}
  <h4 style={{ textAlign: "center", margin: window.innerWidth >= 576 ?"20px 0":"10px 0px" }}>Essential Supplements</h4>

  {/* Content */}
  <div className="container" style={{ cursor: "pointer", padding: '0px 10px' }}>
    <Row noGutters>
      {essential.length > 0 && essential.map((item, index) => (
        <Col lg={3} md={3} sm={6} xs={6} key={index} style={{ padding: '5px', textAlign: 'center' }}>
          <NavLink className="cardCollection" to={`/Products/Collection/${item.id}`}>
            <div className="image-container" style={{ overflow: "hidden", margin: 0 }}>
              <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </div>
            {/* Name below the image */}
            <h5 className='musttry' style={{ marginTop: '10px', color: '#333', display: 'flex', justifyContent: 'center',textAlign:'center' }}>
                  {item.name}
                </h5>
          </NavLink>
        </Col>
      ))}
    </Row>
  </div>
</section>


      



<section className="container" style={{ display: "flex", alignItems: "center", justifyContent: "center" ,marginBottom:'20px' }}>
<Col style={{ cursor: "pointer", padding: '0px 20px' }}>
  <Row noGutters>
    {sub_goal.length > 0 && (
      <>
        <Col xs={12} sm={12} md={6} lg={6} style={{ padding: '5px' }}>
          <NavLink className="cardCollection" to={`/Products/Collection/${sub_goal[1].id}`}>
            <div className="image-container" style={{ overflow: "hidden", position: "relative", margin: 0 }}>
              <img src={sub_goal[1].web_image} alt={sub_goal[1].name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </div>
          </NavLink>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} style={{ padding: '5px' }}>
          <NavLink className="cardCollection" to={`/Products/Collection/${sub_goal[2].id}`}>
            <div className="image-container" style={{ overflow: "hidden", position: "relative", margin: 0 }}>
              <img src={sub_goal[2].web_image} alt={sub_goal[2].name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </div>
          </NavLink>
        </Col>
      </>
    )}
  </Row>
</Col>
  </section>


  {/* <!-- Fourth section --> */}
  <section className="container" style={{marginBottom:'20px'}}>
       
        <Row>      
            <Col md={12}>
                <div className="newarrivalproducts" > 
                <h4 style={{ textAlign: "start", margin: "20px 0" }}>Top Grossing Products</h4>
                </div>
            </Col>
        </Row>  

        <div style={{  paddingBottom: "20px"}}>
        <div className="container1">
          <Carousel autoPlay={true} arrows={false} responsive={productsresponsive} draggable={true} infinite={true} className="stayfit1">   

                { top_grossing.map((element) =>
                                <Col style={{padding:"10px"}}> 
                                 <ProductItemCard
                                    add_cart={AddToCartCall}
                                    url_title={element.url_title}
                                    image={element.image}
                                    rate={element.average_rating}
                                    dis={element.discount_percentage}
                                    name={element.name}
                                    tag={element.item_tag}
                                    elite_price={element.elite_price}
                                    saleprice={element.price}
                                    mrp={element.mrp}
                                    cart_qnt={element.cart_quantity}
                                    id={element.product_id}
                                    notified={element.is_notified}
                                    max_qnty={element.max_cart_quantity}
                                    nuin={element.nuin}
                                    stock={element.stock}
                                    notify_me = {NotifyMeCall}
                                    is_subscribed = {is_subscribed}
                                    clicked = {addClicked === element.product_id}
                                    notifyClicked = {notifyClicked === element.product_id}
                                    index = {element.product_id}
                                    /> 
                               
                                </Col> 
  )}
              
                                  
            </Carousel> </div>
        </div> 
      
  </section>

  <div>
  <div>
  <section className='container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom:'20px' }}>
  <Col style={{ cursor: "pointer", padding: '0px 10px' }}>

  <Row noGutters className='d-flex align-items-center'>
    {main_goal && main_goal.length > 0 && (
      <>
        {/* <div className="image-container" style={{ flex: '4', paddingRight: '2px' }}>
          <NavLink className="cardcategory" to={`/Products/Collection/${sub_goal[0].id}`}>
            <img src={sub_goal[0].web_image} alt="Sub Goal 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </NavLink>
        </div>

        <div className="image-container" style={{ flex: '9', paddingLeft: '2px',borderRadius:'23px' }}>
          <NavLink className="cardcategory" to={`/Products/Collection/${main_goal[0].id}`}>
            <img src={main_goal[0].web_image} alt="Main Goal 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </NavLink>
        </div> */}


        <Col xs={12} sm={12} md={6} lg={6} style={{ padding: '5px' }}>
          
          <NavLink className="cardcategory" to={`/Products/Collection/${sub_goal[0].id}`}>
            <img src={sub_goal[0].web_image} alt="Sub Goal 1" style={{ width: "100%", height: "auto", objectFit: "cover" }} />
          </NavLink>
          
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} style={{ padding: '5px' }}>
          
          <NavLink className="cardcategory" to={`/Products/Collection/${main_goal[0].id}`}>
            <img src={main_goal[0].web_image} alt="Main Goal 1" style={{ width: "100%", height: "auto", objectFit: "cover" }} />
          </NavLink>
          
        </Col>
      </>
    )}
    </Row>
  </Col>
</section>
  </div>
</div>


<section className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" ,marginBottom:"30px"}}>
      {/* Heading */}
      <h4 style={{ textAlign: "center", margin: window.innerWidth >= 576 ?"20px 0":"10px 0px" }}>Must Buy Supplements</h4>

      {/* Carousel */}
      <div className="container" style={{ cursor: "pointer", padding: '5px 20px' }}>
        <Slider {...settings}>
        {/* <Row noGutters> */}
          {must_buy.length > 0 && must_buy.map((item, index) => (
            <div key={index} style={{ padding: '5px' }}>
              <NavLink className="cardcategory" to={`/Products/Collection/${item.id}`}>
                <div className="image-container" style={{ overflow: "hidden", margin:'3px' }}>
                  <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "100%", padding:'5px'}} />
                </div>
                <h6 className='musttry' style={{ marginTop: '10px', color: '#333', display: 'flex', justifyContent: 'center',textAlign:'center' }}>
                  {item.name}
                </h6>
              </NavLink>
            </div>
          ))}
          {/* </Row> */}
        </Slider>
      </div>
    </section>
   </div>
   }
    </>
  );
}else{
  return (
    <section>
    <Container className="mt-100 d-none d-md-block d-lg-block" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
    <Row className="mt-10">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto"  style={{borderRadius:"10px"}}/>
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={12} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        
      
    
      <Row className="mt-20" >
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto" style={{borderRadius:"10px"}} />
  </Col>
    </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
      
        <Row className="mt-20">
        {shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
        </Col>
        ))}
        </Row>
      
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
      </Col>
    </Row>
</Container>
<Container className="mt-80 d-block d-md-none d-lg-none d-sm-block" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
<Row className="mt-10">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={180} width="auto"  style={{borderRadius:"10px"}}/>
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={12} sm={12} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
        
      
    
      <Row className="mt-20" >
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={180} width="auto" style={{borderRadius:"10px"}} />
  </Col>
    </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
      
        <Row className="mt-20">
        {shimmeritems1.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
        </Col>
        ))}
        </Row>
      
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems1.map((_, index) => (
        <Col xs={4} sm={4} md={4} lg={4} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={120} width="auto" />      
        </Col>
        ))}
        </Row>  
       
      </Col>
    </Row>
</Container>
</section>
);
}



}
 
