import React, { useState } from "react";
import {Container,Row,Col,Modal} from 'react-bootstrap'; 
import {ReactComponent as Certificate} from "../assets/images/certificate_svg.svg";
import { RightArrow} from "../MiniComponents/SvgIcons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../SubCss/AuthorozationCss.css";
import Lottie from "lottie-react";
import certified from "../assets/lottie/genuine-trusted.json";
import truck from "../assets/lottie/delivery.json";


const AuthorizationCertificate = (props)=> {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 1,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1  ,
        },
      }; 

      
    const [show, setShowWriteReviewModal] = useState(false);
    const handleClose = () => setShowWriteReviewModal(false);  
    

    return(
        < >   
            <div onClick={setShowWriteReviewModal} style={{padding:'0px'}}>
                {/* <Row style={{backgroundColor:"#ECE3FF"}}> */}
                    {/* <Col lg={12} md={12} sm={12} xs={12} className=""> */}
                    <div style={{ backgroundColor: "#ECE3FF", height: "80px" }}>
                    <div style={{ backgroundColor: "#ECE3FF", height: "80px", position: 'relative' }}>
    <Row style={{ height: "90%", margin: 0 }}>
        <Col lg={3} md={3} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%' }}>
            <div >
                <Lottie className="cetificate" animationData={certified}  />
            </div>
        </Col>

        <Col lg={5} md={5} sm={5} xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%',paddingRight:"0px" }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', borderRadius: '8px' }}>
                <span  className="certificatetest" style={{ color:'#57447C' }}>
                    100% Authentic Product at your doorstep!
                </span>
            </div>
        </Col>

        <Col lg={3} md={3} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Lottie animationData={truck} style={{ height: '80px' }} />
        </Col>
        
        

        <Col lg={1} md={1} sm={1} xs={2}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.785156" y="0.396484" width="40.7229" height="40.7229" rx="20.3615" fill="#B9A6E2"/>
<path d="M17.949 12.9665C17.5274 13.0582 17.2432 13.3088 17.124 13.7365C16.9926 14.204 17.1362 14.5921 17.4693 14.9221C18.7526 16.2023 20.039 17.4795 21.3253 18.7567C21.967 19.3983 22.6056 20.0461 23.3114 20.755L17.4632 26.6032C16.9743 27.092 16.9468 27.7887 17.3776 28.2409C17.8085 28.6931 18.5387 28.6992 19.0184 28.2226C21.2367 26.0165 23.4519 23.8013 25.658 21.583C26.1377 21.1002 26.1499 20.4219 25.6763 19.9483C23.4611 17.7178 21.2398 15.4965 19.0123 13.2813C18.719 12.991 18.3584 12.8749 17.949 12.9665Z" fill="white"/>
</svg>
        </Col>
    </Row>
</div>

</div>

            </div> 
            <div>
                <Modal  className="CertificatesCard special_modal"  show={show} onHide={handleClose}   
                    aria-labelledby="contained-modal-title-vcenter"  classNames={{ overlay: { background: 'black' } }} 
                    centered>
                <Modal.Body  > 
                   
                        <Carousel responsive={responsive}> 
                            <div  >
                                <img
                                    alt="No img"
                                    src={require("../assets/images/certificate1.png")}
                                     
                                />
                            </div> 
                            <div >
                                <img
                                    alt="No img"
                                    src={require("../assets/images/certificate1.png")}
                                     
                                />
                            </div> 
                            <div  >
                                <img
                                    alt="No img"
                                    src={require("../assets/images/certificate1.png")}
                                     
                                />
                            </div> 
                        </Carousel>
                    
                </Modal.Body>
                </Modal>
            </div>
        </>
    );
}
export default AuthorizationCertificate