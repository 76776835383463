import React, { useState, useEffect } from "react";
import { Container, Row, Col, ProgressBar, Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { AverageRatingListApi, SubmitProductReview } from '../Service/ApiCalling';
import '../SubCss/customerratings.css'
const CustomerRatings = (props) => {
    const [show, setShowWriteReviewModal] = useState(false);
    const handleClose = () => setShowWriteReviewModal(false);
    const handleShow = () => setShowWriteReviewModal(true);

    const [avgReviewRating, setAvgReviewRatings] = useState({});
    const [rate, setRate] = useState(0);
    const [file, setFile] = useState([]);
    const [headline, setHeadline] = useState('');
    const [review, setReview] = useState('');
    const [totalReview, setTotalReviews] = useState(null);

    const AverageRatingListFunction = async () => {  
        let data = await AverageRatingListApi(props.id);    
        if (data.error === "0") {
            setAvgReviewRatings(data.avg_review_ratings);
        }
    };

    const AddProductRatingApi = async () => {
        let data = await SubmitProductReview(props.id, rate, review, headline, file);
        if (data.error === "0") {
            // console.log("Review submitted");
            setRate(0);
            setHeadline('');
            setReview('');
            setFile([]);
            handleClose();
            
            // Optionally refresh the average ratings
            AverageRatingListFunction();
        } else {
            // console.log("Error submitting review");
        }
    }

    const handleFileChange = (e) => {
        // console.log(e.target.files);
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
    }

    const handleSubmitReview = (e) => {
        e.preventDefault();
        AddProductRatingApi();
    }

    useEffect(() => {
        setTotalReviews(localStorage.getItem("AvgReviews"));
        AverageRatingListFunction();
    }, []);

    return (
        <>
            <div id="rating_id">
                {avgReviewRating.avg_rate !== 0.0 && (
                    <Row className="d-flex">
                        <h4 className="mb-2" style={{fontWeight:'600',marginBottom:'20px'}}>Customer reviews</h4>
                        <div id="customer_avg_ratings">
                           
                            
                            <Col lg={12} md={12} sm={12} xs={12} className="d-flex align-content-center justify-content-center align-items-center container
                            ">
                            <Col lg={4} md={4} sm={4} xs={4} style={{ textAlign: "start" }}>
    {Number(avgReviewRating.avg_rate) > 0 ? (
        <Col className="" style={{ display: "inline-block", marginLeft: "2px"  }}>
            <div className="rating-container" style={{ paddingLeft: "14px",textAlign: "center",fontSize:'x-large'}}>
                <FontAwesomeIcon icon={faStar} style={{ color: 'gold', }} />
                <span className="prod-rating1" style={{marginTop:"3px"}}> {avgReviewRating.avg_rate}</span>
            </div>

            <div className="" style={{ color: "#7A7A7A", padding: "0px", width: "auto" ,paddingLeft: "7px"}}>
                <span className="prod-rating1"> {Number(totalReview)} {(Number(totalReview) > 1) ? "Reviews" : "Review"}</span>
            </div>
        </Col>
    ) : (
        <></>
    )}
</Col>
                                <Col lg={10} md={10} sm={10} xs={10}>
                                {[5, 4, 3, 2, 1].map((star) => (
                                    <Row className="mb-10" key={star}>
                                        <Col lg={2} md={2} sm={2} xs={2}>
                                            <span style={{ fontFamily: "Inter",paddingRight:"2px" }}>{star}</span>
                                        </Col>
                                        <Col lg={8} md={8} sm={8} xs={8}>
                                            <ProgressBar variant="info" now={avgReviewRating[`r${star}`]} className="mb-10" style={{ marginLeft: "-20px", alignSelf: "center", marginTop: "3px" }} />
                                        </Col>
                                        <Col lg={2} md={2} sm={2} xs={2}>
                                         <span style={{ marginLeft: "-10px", fontFamily: "Inter" }}>
                                           {Math.round(avgReviewRating[`r${star}`])}%
                                             </span>
                                          </Col>

                                    </Row>
                                ))}
                                </Col>
                            </Col>
                            <Row className="mt-20 d-none d-md-block d-lg-block" style={{marginTop:"20px"}}>
                                <hr className="col-lg-12 col-md-12 col-sm-12 col-12"></hr>    
                            </Row> 
                        </div>
                    </Row>
                )}
               <Row className="mt-20">
  <Col lg={12} md={12} sm={12} xs={12}>
    <div className="d-flex align-items-center">
      <h4 style={{ fontFamily: "Inter",  fontWeight: "600" }}>Review this product</h4>                            
    </div>
    <div className="mt-10">
      <span style={{ fontFamily: "Inter" }}>What’s your experience with this product? <br /> Share your thought!</span>
    </div>
    <Button 
      className="mt-10" 
      onClick={handleShow} 
      
      style={{ 
        fontFamily: "Inter", 
        border: "1px solid #D3D3D3",
        padding:"12px 0px",
        backgroundColor: "white", 
        color: "black", 
        borderRadius: "42px",
        width: "100%"  
      }}
    >
      Write a review
    </Button>
  </Col>
</Row>

            </div>

            <Modal className="WriteReviewModal" show={show} onHide={handleClose} style={{ width: "100%", borderRadius: "15px" }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Container className="d-flex align-content-center" style={{ backgroundColor: "white" }}>
                        <Col lg={12} md={12} sm={12} xs={12} style={{padding:'20px'}}>
                            <Row>
                                <Col>
                                    <h3>Review The Product</h3>
                                </Col>
                                <Col className="text-end">
                                    <h3 onClick={handleClose}>X</h3>
                                </Col>
                            </Row>
                            <Row className="mt-40">
                                <Col lg={3} md={3} sm={3} xs={3}>
                                    <img src={props.image} alt={props.name} fluid />
                                </Col>
                                <Col lg={9} md={9} sm={9} xs={9} style={{display:'flex',alignItems:'center'}}>
                                    <h5 className="text-justify " style={{fontWeight:"400"}} >{props.name}</h5>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "20px" }}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h5>Rate this Product</h5>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <FontAwesomeIcon 
                                            key={star} 
                                            icon={faStar} 
                                            onClick={() => setRate(star)} 
                                            style={{ width: "35px", height: "35px", color: rate >= star ? '#219ebc' : 'lightgray' }} 
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <Form onSubmit={handleSubmitReview}>
                                <Row style={{ marginTop: "20px" }}>
                                    <h5>Add a Headline</h5>
                                    <Form.Group className="mb-3">
                                        <Form.Control 
                                           
                                            placeholder="Write a headline" 
                                            style={{ border:'1px solid black', }} 
                                            className="headline"
                                            value={headline}
                                            onChange={(e) => setHeadline(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Container style={{ marginTop: "20px" }}>
                                    <Row>
                                        <Col lg={2} md={2} sm={2} xs={4}>
                                            <div className="dotted-border-box" style={{ width: "100px", height: "100px" }}>
                                                <div className="centered-content">+</div>
                                                <input 
                                                    type="file" 
                                                    onChange={handleFileChange} 
                                                    style={{ opacity: 0, position: "relative", zIndex: 1, width: "100px", height: "100px" }} 
                                                />
                                               
                                            </div>
                                        </Col>
                                        
                                        {file.map((element, index) => (
                                            <Col lg={2} md={2} sm={2} xs={4} key={index}>
                                                <img src={element} alt="Uploaded file" className="dotted-border-box" style={{ padding: 0, width: "100px", height: "100px" }} />
                                            </Col>
                                        ))}
                                    </Row>
                                    
                                </Container>
                                <h5 style={{marginTop:'15px'}}>Add a photo or video</h5>
                                <h6 style={{fontWeight:'400',color:"#5e6164"}}>Images and videos are more helpful than text</h6>

                               
                                <Row style={{ marginTop: "20px" }}>
                                <h5> Write a review</h5>
                                    <Form.Group className="">
                                        <Form.Control 
                                    
                                            as="textarea"
                                            rows={10} 
                                            placeholder="Write something about the product" 
                                            style={{border:'1px solid black',background:'white' }} 
                                            value={review}
                                            onChange={(e) => setReview(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row style={{ display: "flex", marginTop: "10px", justifyContent: "flex-end" }}>
                                    <Button variant="primary" type="submit" style={{ borderColor: "orange", backgroundColor: "orange", color: "black", width: "100px" }}>
                                        Submit
                                    </Button>
                                </Row>
                            </Form>
                        </Col>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CustomerRatings;
