import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTags = ({ 
  title, 
  description, 
  url, 
  image, 
  alt, 
  keywords, 
  canonical, 
  ogTitle, 
  ogUrl, 
  ogDescription, 
  ogImage, 
  schema
}) => {
  console.log("Rendering MetaTags with schema:", schema);

  
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonical} />
      
      {/* Open Graph / Facebook Meta Tags */}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      
      <meta name="robots" content="index, follow"/> 
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta name="author" content="Nutsby Fitcare"/>
      <meta http-equiv="Content-Language" content="en-IN" />
      <meta charset="UTF-8"/>

      <meta name="geo.region" content="IN" /> 
      <meta name="geo.placename" content="India" /> 
      <meta name="geo.position" content="20.5937;78.9629" /> 
      <meta name="ICBM" content="20.5937, 78.9629" /> 
      
      {schema && (
      <script type="application/ld+json">
      {JSON.stringify(schema)}
      </script>
      )}
      {/* Dynamic Schema
      {schema && (
        <script
          type="application/ld+json"
          {JSON.stringify(schema)}
        />
      )} */}
    </Helmet>
  );
};

export default MetaTags;
