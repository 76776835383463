import React, {useEffect, useState} from "react";
import {GetOrderDetails, RepeatOrder} from "../../Service/ApiCalling";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Card,
    Image,
    Offcanvas
} from 'react-bootstrap';
import "../../SubCss/OrderDetails.css";

import {ReactComponent as HomeIcon} from '../../assets/images/homegroup2.svg';
import {ReactComponent as WorkIcon} from '../../assets/images/presentation.svg';
import {ReactComponent as OtherIcon} from '../../assets/images/menu.svg';
import {ReactComponent as PersonIcon} from '../../assets/images/homegroup1.svg';
import {ReactComponent as HelpIcon} from '../../assets/images/help.svg';
import {ReactComponent as backIcon} from '../../assets/images/back.svg';
import CartList from "../CartList";
import { Ellipsis } from "react-bootstrap/esm/PageItem";
import { ShimmerDiv } from 'shimmer-effects-react';
export default function OrderDetails() {

    const params = useParams();
    const [order_id,
        setOrderId] = useState(params.id);
    const [coupon_prods,
        setCoupon_prods] = useState([]);
    const [free_prods,
        setFree_prods] = useState([]);
    const [order_prods,
        setOrder_prods] = useState([]);
    const [order_details_data,
        setOrderDetails_data] = useState([]);
    const [contact_details,
        setContactDetails] = useState([]);
    const [address_details,
        setAddressDetails] = useState([]);
    const [payment_details,
        setPayment_details] = useState([]);

    const [cartshow,
        setcartShow] = React.useState(false);

    const handlecartClose = () => setcartShow(false);
    const handlecaartShow = () => setcartShow(true);
    const shimmeritems = Array.from({ length: 5 });

    const [shimmer_loading,setShimmerLoading] = useState(true);

    let history = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
        backgroundColor: 'white', // Background color stays the same
        color: 'black',
        borderRadius: '30px',
        border: '0.5px solid black', // Border remains constant
        padding: '10px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: isHovered ? '0 0 0 0.5px black' : 'none', // Ensure border effect with boxShadow
        // transition: 'box-shadow 0.3s ease', // Smooth transitio
    };
   
    const getDetails = async() => {
        let orderDetailsData = await GetOrderDetails(order_id);
        if (orderDetailsData.error === "0") {
            setOrder_prods(orderDetailsData.order_products);
            if (orderDetailsData.free_products.length > 0) {
                setFree_prods(orderDetailsData.free_products);
            }

            if (orderDetailsData.coupon_products.length > 0) {
                setCoupon_prods(orderDetailsData.coupon_products);
            }
            setContactDetails(orderDetailsData.contact_details);
            setAddressDetails(orderDetailsData.delivery_address);
            // if(orderDetailsData.contact_details.length>0){ }
            // if(orderDetailsData.delivery_address.length>0){ }

            setOrderDetails_data(orderDetailsData.order_details);

            setPayment_details(orderDetailsData.payment_details);

            // console.log(order_details_data,"test");
        
        setShimmerLoading(false);
      
    }else{
      setShimmerLoading(false);
    }
    };

    const Repeat_Order = async() => {
        let RepeatOrderApi = await RepeatOrder(order_id);
        if (RepeatOrderApi.error === "0") {
            handlecaartShow();
        }

    }

    useEffect(() => {
        getDetails();
    }, []);

    const getDeliveryStatus = (status) => {
        switch (status) {
            case "Order Shipped":
                return "Expected Delivery"; // Your desired color for Order Shipped
            case "Order Packed":
                return "Expected Delivery"; // Your desired color for Order Packed
            case "Order Cancelled":
                return "Cancelled"; // Your desired color for Order Cancelled
            case "Order Delivered":
                return "Order Delivered On"; // Your desired color for Order Delivered
            case "Order Completed":
                return "Order Delivered On";
            default:
                return "Expected Delivery"; // Default color
        }
    };

    const getIcon = (type) => {
        switch (type) {
            case 'Home':
                return <HomeIcon
                    style={{
                    width: "25px",
                    height: "25px"
                }}/>;
            case 'Work':
                return <WorkIcon
                    style={{
                    width: "30px",
                    height: "30px"
                }}/>;
            case 'Other':
                return <OtherIcon
                    style={{
                    width: "30px",
                    height: "30px"
                }}/>;
            default:
                return <HomeIcon
                    style={{
                    width: "30px",
                    height: "30px"
                }}/>;
        }
    };
    if(!shimmer_loading){
    return ( <> <div className="">
       
        <Container style={{
            marginTop: "2rem"
        }}>
            <Row className="class1 justify-content-between align-items-center mt-10">
    {/* Back Button */}
    <Col xs="auto">
        <Button
            className="order_status1"
            type="button"
            style={{
                borderColor: "transparent",
                color: "black",
                borderRadius: "5px",
                fontFamily: "Inter",
                display: "flex",
                alignItems: "center",
                padding:"0px",
                background:"none"
            }}
            onClick={() => history(-1)}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="23"
                viewBox="0 0 13 23"
                fill="none"
                style={{
                    width: "7px",
                    height: "17px",
                    marginRight: "6px",
                }}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9988 0.94219C11.6066 0.549994 10.9708 0.549993 10.5786 0.94219L0.536048 10.9848C0.143851 11.377 0.143851 12.0127 0.536048 12.4049L10.5786 22.4476C10.9708 22.8397 11.6066 22.8397 11.9988 22.4476C12.391 22.0554 12.391 21.4195 11.9988 21.0273L2.66642 11.6949L11.9988 2.36235C12.391 1.97015 12.391 1.33439 11.9988 0.94219Z"
                    fill="black"
                />
            </svg>
            Back
        </Button>
    </Col>

    {/* Order Again Button */}
    <Col xs="auto">
        <Button
            className="order_status"
            onClick={() => Repeat_Order()}
            type="submit"
            style={{
                fontWeight: "500",
                borderColor: "#FFB703",
                backgroundColor: "#FFB703",
                color: "black",
                fontFamily: "Inter",
                borderRadius: "30px",
                padding:"5px 30px",
                fontSize:'16px'
            }}
        >
            Order Again
        </Button>
    </Col>
</Row>

        </Container>

        <Container className='mt-50'>
            <Row>
                <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                    <Row className=" bg-white" style={{borderRadius:"16px",padding:"20px 20px 10px 20px", color:"black"}}>
                        <Col xs={5} md={6} sm={6} lg={8} xl={8} xxl={8} style={{paddingLeft:"24px"}}>
                            <p
                            className="orderstatus1"
                                style={{
                                marginBottom: "0rem",
                                fontWeight:'600'
                              }}>
                                {getDeliveryStatus(order_details_data.order_status)}
                            </p>
                            <p className="order_delivery_date" style={{fontFamily:"Inter", marginBottom: "0rem"}}>
                                {order_details_data.delivery_date}
                            </p>
                            {/* <NavLink to={}> */}
                                    {order_details_data.track_url ? (
                                    <NavLink to={order_details_data.track_url} target="_blank">
                                      <Button
                                        className="viewDetailsbtn"
                                        variant="outline-none"
                                        style={{
                                          padding: '0px',
                                          marginBottom: '10px',
                                          display: 'flex',           
                                          alignItems: 'center',     
                                          gap: '5px',                
                                        }}
                                      >
                                        Track Order
                                        <svg
                                          width="7"
                                          height="15"
                                          viewBox="0 0 7 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="icondetails"
                                          style={{
                                            marginLeft: '5px',  
                                          }}
                                        >
                                          <path
                                            d="M6.58443 4.77775C6.57675 4.77134 6.5665 4.76878 6.55882 4.76238L1.61991 0.292135C1.34204 0.0424375 0.891302 0.0424375 0.613432 0.292135C0.610871 0.294697 0.609591 0.297257 0.608311 0.299818C0.541027 0.355373 0.486686 0.424952 0.449086 0.50369C0.411487 0.582429 0.391535 0.668427 0.390625 0.755678L0.390625 9.69745C0.392061 9.7863 0.412989 9.87375 0.451931 9.95363C0.490873 10.0335 0.54688 10.1039 0.615994 10.1597L0.613433 10.1623C0.753246 10.2831 0.931874 10.3496 1.11667 10.3496C1.30147 10.3496 1.4801 10.2831 1.61991 10.1623L6.58443 5.68178C6.64981 5.626 6.70232 5.5567 6.73832 5.47867C6.77432 5.40063 6.79297 5.31571 6.79297 5.22976C6.79297 5.14382 6.77432 5.0589 6.73832 4.98086C6.70232 4.90282 6.64981 4.83352 6.58443 4.77775Z"
                                            fill="#008EB0"
                                          />
                                        </svg>
                                      </Button>
                                    </NavLink>
                                  ) : null}



                            {/* </NavLink> */}

                        </Col>
                        <Col xs={7} md={6} sm={6} lg={4} xl={4} xxl={4} className='text-end' style={{paddingRight:"17px",}}>
                            <p
                                className="order_status"
                                style={{
                                marginBottom: "0rem",
                             
                            }}>
                                #{order_details_data.id}
                                -{order_details_data.order_number}
                            </p>
                            <p className='order-prod-muted-text order-date '>
                                Order Date:&nbsp; {order_details_data.order_date}
                            </p>
                            
                        </Col>
                    </Row>

                    {/* Order Products */}

                  <Row className=" bg-white  mt-10" style={{borderRadius:"16px",justifyContent:"center"}}> {order_prods.map((orders, index) => (
                        <Row>  
                            <NavLink to={/product_details/+orders.product_id+`/`+orders.name}
>                                                  
                          <Col md={12} sm={12} lg={12} xl={12} xxl={12} style={{}}>
                          <Row className="justify-content-center align-items-center" style={{padding:"10px 0px 0px 0px", color:"black"}} >
                              {/* Column for Image */}
                              <Col xs="auto">
                                  <div className="img-fluid"> 
                                      <img src={orders.image} className="order-product-img" alt={`Product ${index + 1}`} />
                                  </div>
                              </Col>
                              

                              {/* Column for Product Details */}
                              <Col>
                                  {/* Product Name */}
                                  <div className='order-prod-name' style={{maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical",fontFamily: "Inter"}} >
                        
                        {orders.short_name}

                       </div>

                                  <div className="d-flex justify-content-between">
                                    {Number(order_details_data.is_premium)===1?<span className='order-prod-name premimum-tag-text' style={{ fontFamily: "Inter", fontWeight: '600', color:'black' }}>
                                          {orders.product_discounted_amount}
                                      </span>:
                                      <span className='order-prod-name' style={{ fontFamily: "Inter", fontWeight: 600 }}>
                                          {orders.product_discounted_amount}
                                      </span>
                                      } 
                                      {orders.product_quantity==="0"?<></>:
                                      <span className='order-prod-muted-text' style={{paddingRight:"10px"}}>
                                          Qty. {orders.product_quantity}
                                      </span>
                                      }
                                  </div>
                              </Col>
                          </Row>
                          </Col>
                          </NavLink>   
                                {index < order_prods.length - 1 && (
                                <hr
                                    style={{
                                    width: '87%',
                                    marginBottom: '1rem',
                                    color: "#E5E8F4",
                                    opacity: "2.25",
                                    marginLeft: "13%"
                                }}/>
                                   )}
                          
                        </Row>
                    ))}</Row>

                    {/* free products */}

                    {< Col md = {
                        12
                    }
                    sm = {
                        12
                    }
                    lg = {
                        12
                    }
                    xl = {
                        12
                    }
                    xxl = {
                        12
                    } > <Row className="bg-white">
                        {free_prods.length > 0
                            ? <Card
                                    className='free-products bg-white   mt-10'
                                    style={{
                                   
                                    borderRadius:"16px"
                                }}>
                                    <Card.Header
                                        style={{
                                        backgroundColor: "transparent"
                                    }}>
                                        <div
                                            style={{
                                            position: 'absolute',
                                            top: '-2px',
                                            left: '-2px',
                                            backgroundColor: '#E3FAFF',
                                            padding: '2px',
                                            borderTopLeftRadius: '17px',
                                            borderBottomRightRadius: '27px'
                                        }}>
                                            <span
                                            className="free_item"
                                                style={{
                                               
                                                padding: "6px 19px 9px 19px"
                                            }}>Free Item(s)</span>
                                        </div>
                                    </Card.Header>

                                    <Card.Body style={{padding:"10px 0px 0px 0px"}}>
                                        {free_prods.map((free, index) => (
                                            <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12}>
                                                <Row className="justify-content-center align-items-center">
                                                   {/* Column for Image */}
                                                   <Col xs="auto">
                                                          <div className="img-fluid">
                                                              <img src={free.image} className="order-product-img" alt={`Product ${index + 1}`} />
                                                          </div>
                                                      </Col>

                                                        {/* Column for Product Details */}
                                                <Col>
                                                    {/* Product Name */}
                                                    <div className='order-prod-name' style={{maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical",fontFamily: "Inter", fontWeight: 400}} >
                        
                                                  {free.short_name}

                                                 </div>

                                                    <div className="d-flex justify-content-between">
                                                        <span className='order-prod-name' style={{fontFamily: "Inter", fontWeight: 600,color:"#219EBC"}}>
                                                        Free
                                                        </span>
                                                        <span className='order-prod-muted-text'>
                                                            Qty. {free.product_quantity}
                                                        </span>
                                                    </div>
                                                </Col>
                                            
                                                </Row>
                                                {index < free_prods.length - 1 && (
                                                <hr
                                                    style={{
                                                    width: '82%',
                                                    marginBottom: '1rem',
                                                    color: "#E5E8F4",
                                                    opacity: "2.25",
                                                    marginLeft: "17%"
                                                }}/>)}
                                            </Col>
                                        ))}
                                    </Card.Body>

                                </Card>
                            : <> </>
}
                    </Row> </Col>}

                    {/* Coupon Products */}

                    {< Col md = {
                        12
                    }
                    sm = {
                        12
                    }
                    lg = {
                        12
                    }
                    xl = {
                        12
                    }
                    xxl = {
                        12
                    } >
                       <Row className="bg-white">
                        {coupon_prods.length > 0
                            ? <Card
                                    className='coupon-products  bg-white  mt-10'
                                    style={{
                                      
                                      borderRadius:"16px"
                                  }}>
                                    <Card.Header
                                        style={{
                                        backgroundColor: "transparent"
                                    }}>
                                        <div
                                            style={{
                                            position: 'absolute',
                                            top: '-2px',
                                            left: '-2px',
                                            backgroundColor:'#FFB703',
                                            padding: '1px',
                                            borderTopLeftRadius: '17px',
                                            borderBottomRightRadius: '27px'
                                        }}>
                                            <span
                                            className="free_item"
                                                style={{
                                              
                                                    padding: "6px 19px 9px 9px"
                                            }}>Coupon Gifts</span>
                                        </div>
                                    </Card.Header>
                                      <Card.Body style={{padding:"10px 0px 0px 0px"}}> 
                                        {coupon_prods.map((coupon, index) => (
                                          <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12} >
                                              <Row className="justify-content-center align-items-center">    
                                                      {/* Column for Image */}
                                                    <Col xs="auto">
                                                        <div className="img-fluid">
                                                            <img src={coupon.image} className="order-product-img" alt={`Product ${index + 1}`}/>
                                                        </div>
                                                    </Col>

                                                    <Col>
                                                    {/* Product Name */}
                                                    <div className='order-prod-name' style={{maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical",fontFamily: "Inter", fontWeight: 400}} >
                        
                                               {coupon.short_name}
        
                                              </div>

                                                    <div className="d-flex justify-content-between">
                                                        <span className='order-prod-name' style={{fontFamily: "Inter", fontWeight: 600,color:"#219EBC"}}>
                                                        Free
                                                        </span>
                                                        <span className='order-prod-muted-text'>
                                                            Qty. {coupon.product_quantity}
                                                        </span>
                                                    </div>
                                                </Col>
                                              </Row>
                                              {index < coupon_prods.length - 1 && (<hr
                                      style={{
                                      width: '82%',
                                      marginBottom: '1rem',
                                      color: "#E5E8F4",
                                      opacity: "2.25",
                                      marginLeft: "17%"
                                  }}/>)}

                                          </Col>
                                        
                                          ))}
                                      </Card.Body>
                                </Card>
                            : <> </>}
                    </Row> </Col>}

                </Col>
            </Row>
        </Container>
 {/* Address */}
<Container 
  className='coupon-products  bg-white rounded mt-10'
  style={{
   
    borderRadius: "16px",
    padding:"10px 0px 0px 0px"
  }}
>
  {/* Address Details */}

  <div className="cust_address_container" style={{ padding: "10px", fontFamily: "'Inter', sans-serif" }}>
  <div className="cust_address_icon" style={{ display: "flex", alignItems: "center" }}>
    <div className="cust_address-icon" style={{ marginRight: "10px" ,width:'20px',height:'20px',paddingLeft:'20px'}}>
      {getIcon(address_details.type)}
    </div>
    <div className="order-address-details" style={{ flex: "1",marginLeft:'20px' ,paddingLeft:'30px'}}>
      <div className="cust-address-type" style={{ fontWeight: "bold" }}>
        <span style={{ color: "#777777", fontWeight: "normal" }}>Delivery at </span>
        {address_details.type}
      </div>
      <div className="cust-address" style={{ wordBreak: "break-word" }}>
        {address_details.address_line_2}
      </div>
    </div>
  </div>
  
  {/* Divider */}
  <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
    <hr
      style={{
        width: '100%',
        margin: '0px',
        color: "#E5E8F4",
        opacity: "0.5",
        marginLeft:"50px"
      }}
    />
  </div>
</div>


  {/* Contact Details */}
  <div className="cust_address_details" style={{ display: "flex", alignItems: "center", padding: "10px",paddingBottom:"5px",paddingTop:"5px", fontFamily: "'Inter', sans-serif" }}>
  {/* Icon Container */}
  <div className="cust_address_icon" style={{ display: "flex"}}>
    <div className="cust_address-icon" style={{ marginRight: "20px" ,marginTop:"2px",paddingLeft:'20px'}}>
    <PersonIcon
      style={{
        width: "25px",
        height: "25px"
      }}
    />
  </div>

  {/* Details Container */}
  <div className="order-address-details" style={{ flex: "1", display: "flex", flexDirection: "column",paddingLeft:'20px' }}>
    <div className="cust-address-type" style={{ fontWeight: "bold", }}>
      {contact_details.name}
    </div>
    <div className="cust-address" style={{ wordBreak: "break-word" }}>
      {contact_details.mobile_number}
    </div>
  </div>
</div>
</div>
</Container>
        {/*payment Details*/}
        <Container className="mt-10 bg-white" style={{padding:"10px 0px 0px 0px",borderRadius:"15px"}}>
            <Col md={12} sm={12} lg={12} xl={12} xxl={12}>

                <Card 
                    className='addressCard d-flex justify-content-center mt-10'
                    style={{
                    borderRadius: "10px",
                    border: "none",
                    padding:"0px!important"
                }}>
                    <Card.Body style={{padding:"5px 20px 15px 30px"}} >

                        <Row>
                            <Col xs={8} md={8} sm={8} lg={8} xl={8} xxl={8}>
                                <span
                                    className='order-prod-title'
                                    style={{
                                    marginBottom: "0.4rem",
                                }}>
                                    Price({order_prods.length} Items)
                                </span>

                                
                                <span
                                    className='order-prod-title'
                                    style={{
                                    marginBottom: "0.4rem",
                                   
                                }}>
                                    Shipping
                                </span>

                                {order_details_data.coupon_discount_amount !== "₹0.00" && (
                                    <span
                                        className='order-prod-title'
                                        style={{
                                        marginBottom: "0.4rem",
                                       
                                    }}>
                                        Coupon Discount
                                    </span>
                                )}

                                {order_details_data.rewards_discount_amount !== "₹0.00" && (
                                    <span
                                        className='order-prod-title'
                                        style={{
                                        marginBottom: "0.4rem",
                                      
                                    }}>
                                        FitCash Discount
                                    </span>
                                )}

                                {order_details_data.is_premium !== "0" && (
                                    <span
                                        className='order-prod-title'
                                        style={{
                                        marginBottom: "0.4rem",
                                      
                                    }}>
                                        Elite Membership Discount
                                    </span>
                                )}
                            </Col>

                            <Col className='text-end'>
                                <span
                                    className='order-prod-title'
                                    style={{
                                    marginBottom: "0.4rem",
                                    
                                }}>{order_details_data.net_amount}
                                </span>

                                {order_details_data.delivery_fee_amount !== "₹0.00"
                                    ? <span
                                            className={order_details_data.is_premium===1?'order-prod-title':'order-prod-title'}
                                            style={{
                                            marginBottom: "0.4rem",
                                           
                                        }}>
                                            {order_details_data.delivery_fee_amount}
                                        </span>
                                    : 
                                    <span
                                        className={order_details_data.is_premium===1?'order-prod-title':'order-prod-title'}
                                        style={{
                                        marginBottom: "0.4rem",
                                        
                                    }}>
                                        Free
                                    </span>
                                  }

                                {order_details_data.coupon_discount_amount !== "₹0.00" && (
                                    <span
                                        className={order_details_data.is_premium===1?'order-prod-title':'order-prod-title '}
                                        style={{
                                        marginBottom: "0.4rem",
                                       
                                    }}>
                                        {order_details_data.coupon_discount_amount}
                                    </span>
                                )}

                                {order_details_data.rewards_discount_amount !== "₹0.00" && (
                                    <span
                                        className='order-prod-title'
                                        style={{
                                        marginBottom: "0.4rem",
                                      
                                    }}>
                                        {order_details_data.rewards_discount_amount}
                                    </span>
                                )}
                                 {order_details_data.is_premium !== "0" && (
                                    <span
                                        className='order-prod-title'
                                        style={{
                                        marginBottom: "0.4rem",
                                      
                                    }}>
                                        {order_details_data.total_saved}
                                    </span>
                                )}


                            </Col>
                        </Row>
                        <hr
                            style={{
                            width: '100%',
                            marginTop: '1rem',
                            marginBottom: '0.5rem',
                            color: "#E5E8F4",
                            opacity: "2.25"
                        }}/>
                        <Row>
                            <Col xs={8} md={8} sm={8} lg={8} xl={8} xxl={8}>
                                <span
                                    className=''
                                    style={{
                                    marginBottom: "0.4rem",
                                    fontSize:"20px"
                                }}>
                                    Total
                                </span>
                            </Col>

                            <Col xs={4} md={4} sm={4} lg={4} xl={4} xxl={4} className='text-end'>
                                <span
                                    className=''
                                    style={{
                                    marginBottom: "0.4rem",
                                    fontSize:"17px",
                                    fontWeight:"500"
                                }}>{order_details_data.total_amount}
                                </span>

                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Container>

        {/* Call Support */}

        <Container className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-10 " style={{marginLeft:'0px'}}>
        
<h1 className="help"  style={{color:'#E3E3E3',fontWeight:"800",fontStyle:"italic"}}>We’re always here to help you!</h1>
<NavLink to={`/help/${order_id}`}>
<span style={{color:"#219EBC"}} >
      
      &nbsp;Help   <svg width="7" height="auto"  viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8561 14.6182C10.8437 14.6079 10.8272 14.6037 10.8148 14.5934L2.85282 7.38696C2.40487 6.98442 1.67823 6.98442 1.23028 7.38696C1.22615 7.39109 1.22409 7.39522 1.22202 7.39935C1.11356 7.4889 1.02595 7.60107 0.965339 7.72801C0.904725 7.85494 0.87256 7.99358 0.871093 8.13423L0.871094 22.5492C0.873408 22.6925 0.907146 22.8335 0.969925 22.9622C1.0327 23.091 1.12299 23.2044 1.23441 23.2944L1.23028 23.2986C1.45567 23.4934 1.74364 23.6006 2.04155 23.6006C2.33946 23.6006 2.62743 23.4934 2.85282 23.2986L10.8561 16.0756C10.9615 15.9857 11.0462 15.874 11.1042 15.7482C11.1622 15.6223 11.1923 15.4854 11.1923 15.3469C11.1923 15.2083 11.1622 15.0714 11.1042 14.9456C11.0462 14.8198 10.9615 14.7081 10.8561 14.6182Z" fill="#008EB0"/>
      </svg> &nbsp;
    </span> </NavLink>
        </Container>
        <Container className='mt-100'/> {/* cart */}
        <Offcanvas
            show={cartshow}
            onHide={handlecartClose}
            placement='end'
            style={{
            width: "30rem"
        }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Cart</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <CartList></CartList>
            </Offcanvas.Body>
        </Offcanvas>
    </div> </>
)
}
else{
    return (
        <Container className="mt-10" style={{ paddingBottom: "20px", alignContent: "center" }}>
        <Row>
          {shimmeritems.map((_, index) => (
            <Col key={index} xs={12} sm={12} md={12} lg={12} className="mb-2">
              <ShimmerDiv mode="light" height={100} width={100} style={{ margin: "0 auto" }} />
              {/* Add margin or other styles for small screens */}
              <Row className="mt-2" />
            </Col>
          ))}
        </Row>
      </Container>)
  }
  }