import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button, Form, Image, Badge, Spinner } from 'react-bootstrap';
import { EditProfileDetails, ProfileDetails,GetHeaderDetailsApi } from '../../Service/ApiCalling';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ShimmerDiv, ShimmerTable, ShimmerTitle } from 'shimmer-effects-react';
import { ToastContainer, toast } from 'react-toastify';
import "../../SubCss/profile.css"
import { useCart } from "./MiniComponents/CartContext";



function Profile() {
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [dob, setDOB] = useState(null)
  const [email, setEmail] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); 
  const [profile_details, setProfileDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const {setheadername,setimage} = useCart();

  const getDetails = async () => {
    try {
      let profileDetailsData = await ProfileDetails();
      if (profileDetailsData.error === "0") {
        setLoading(false);
        setProfileDetails(profileDetailsData.details);
        setName(profileDetailsData.details.name);
        setEmail(profileDetailsData.details.email_id);

        // Handle invalid date format
        const dateOfBirth = profileDetailsData.details.date_of_birth;
        setDOB(dateOfBirth !== "0000-00-00" ? new Date(dateOfBirth) : null);
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
      toast.error("Failed to fetch profile details.", { autoClose: 3000 });
    }
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    let image = document.getElementById('image');
    if (image) {
      image.src = URL.createObjectURL(file);
    }
  };

  // Handle date change
  const handleDateChange = (date) => {
    setDOB(date);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false || !dob) {
      event.stopPropagation();
      toast.error("Please fill in all required fields, including a valid Date of Birth.", { autoClose: 3000 });
    } else {
      try {
        let submitDetails = await EditProfileDetails(name, email, dob ? dob.toISOString() : null, selectedFile);
        if (submitDetails.error === "0") {
          toast.success(submitDetails.message, { autoClose: 3000 });
          setLoading(false);

          const headerData = await GetHeaderDetailsApi();
          if (headerData.error === "0") {
            setheadername(headerData.user_name);
            setimage(headerData.user_profile);
          }
        } else {
          toast.error(submitDetails.message, { autoClose: 3000 });
        }
      } catch (error) {
        console.error("Error submitting details:", error);
        toast.error("Failed to submit details.", { autoClose: 3000 });
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    getDetails();
  }, []);

  if(!loading){
    return (
      <>
  
        <div>
          <Container className=''>
            <Row>
            <Row>
 
   
      {/* Profile Image for Large Screens - Aligned to the End */}
      <Col className="d-flex profile mt-20 mb-20 ">
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <Image
            src={profile_details.profile_image_dir_file_path}
            className="profile_logo-img"
            style={{ width: "125px", height: "125px", objectFit: "cover", borderRadius: '100px' }}
            alt="Profile"
            id="image"
            roundedCircle
          />

          <label
            htmlFor="file-upload"
            style={{
              display: "block",
              backgroundColor: "#023047",
              width: '30px',
              height: '30px',
              position: 'absolute',
              bottom: 0,
              right: 0,
              borderRadius: '50%',
              border: '2px solid white',
            }}
          >
            <input
              id="file-upload"
              type="file"
              name="profile_picture"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Container className="d-flex justify-content-center" style={{}} alt="Corner">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                style={{ position: "absolute", bottom: "7px" }}
              >
                <path d="M9.97664 2.13947L8.96869 1.13152C8.55635 0.71918 7.89965 0.71918 7.48731 1.13152L6.76953 1.8493L9.25886 4.33863L9.97664 3.62085C10.389 3.20851 10.389 2.53654 9.97664 2.13947Z" fill="white" />
                <path d="M0.523423 10.2181C0.492879 10.325 0.523423 10.4319 0.599783 10.5236C0.676143 10.5999 0.798318 10.6305 0.905222 10.5999L3.59308 9.83634L1.27175 7.53027L0.523423 10.2181Z" fill="white" />
                <path d="M1.61719 7.00293L6.33559 2.28453L8.81896 4.7679L4.10056 9.4863L1.61719 7.00293Z" fill="white" />
              </svg>
            </Container>
          </label>
        </div>
      </Col>
    </Row>
  
              <Col lg={12} md={12} sm={12} xs={12}>
                
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label style={{fontFamily:"Inter",fontWeight:'600'}}>Name</Form.Label>
                        <Form.Control
                          required
                          name="name"
                          type="text"
                          className="form-control custom-placeholder"
                          placeholder="Enter your name"
                          style={{ backgroundColor: "#F4F5FA", fontFamily: 'Inter',border:'none'}}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          maxLength={20}
                          // pattern="[a-zA-Z\s]+"
                          title="Name should be alphanumeric (letters and numbers only)"
                        />

                        <Form.Control.Feedback type="invalid" style={{fontFamily:"Inter",}}>
                         Name should be alphanumeric (letters and numbers only).
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{fontFamily:"Inter", fontWeight:'600'}}>Email Address</Form.Label>
                        <Form.Control
                          required
                          name="email"
                          type="text"
                          className="form-control custom-placeholder"
                          placeholder="Enter your e-mail id"
                          pattern="\S+@\S+\.\S+"
                          style={{ backgroundColor: "#F4F5FA", fontFamily: 'Inter',border:"none"}}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" style={{ fontFamily: "Inter", }}>
                          Please provide a valid email address (e.g., user@example.com).
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
  <Form.Group className="mb-3" controlId="formBasicDob">
    <Form.Label style={{ fontFamily: "Inter", fontWeight: '600' }}>Date Of Birth</Form.Label>
    <div className="datepicker-wrapper">
      <DatePicker
        required
        name="date"
        className={`form-control custom-placeholder ${!dob && validated ? 'is-invalid' : ''}`} 
        placeholderText="Enter your date of birth"
        style={{ backgroundColor: "#F4F5FA", fontFamily: 'Inter', border: 'none' }}
        selected={dob}
        onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
      />
      {!dob && validated &&(
        <div className="invalid-feedback1" style={{ fontFamily: "Inter" }}>
          Please provide your date of birth.
        </div>
      )}
    </div>
    {/* <Form.Control.Feedback type="invalid" style={{ fontFamily: "Inter" }}>
      Please provide your date of birth.
    </Form.Control.Feedback> */}
  </Form.Group>
</Col>

                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group className="mb-3" controlId="formBasicPhone">
                        <Form.Label style={{fontFamily:"Inter", fontWeight:'600'}}>Phone No.</Form.Label>
                        <Form.Control
                          readOnly
                          type="tel"
                          className="form-control custom1-placeholder"
                          value={profile_details.mobile_number}
                          placeholder="Enter your phone no."
                          maxLength={10}
                          pattern="[6-9]{1}[0-9]{9}"
                          style={{ backgroundColor: "#cbccd1b5",fontFamily:'Inter',border:'none' }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <Row xl={5} lg={5} md={3} sm={3} xs={3} style={{ display: "flex", marginTop: "10px", justifyContent: "flex-end" }}>
                        <Button variant="primary" type="" style={{ backgroundColor: "#FFB703", color: "black", borderRadius: "25px", marginRight: "14px",fontFamily:"Inter",padding:"10px"}} align="end" >
                          Save
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
  
            </Row>
            <ToastContainer />
          </Container>
          <Container className='mt-100' />
        </div>
  
      </>
    )
  }else{
    return (
    <Container className="mt-20" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
            
      <Row className="profile d-flex">
          <Col  xs={5} sm={5} md={4} lg={2}>
          <ShimmerDiv mode="light"  height={150} width={150} rounded={50} />

          </Col>
      </Row>
  <Row>
  <ShimmerTable mode="light" row={2} col={2} border={0} borderColor={"#cbd5e1"} rounded={0.25} rowGap={0} colPadding={[10, 5, 10, 5]} />
  
  
  </Row>      
   
  
</Container>);
  }
}


export default Profile