import React, { useEffect, useState,useRef } from "react";
import {Container, Row, Col,Button,Form  } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { GenerateOtp,UserRegistration,VerifyOtp,verify_referral_code } from '../Service/ApiCalling';
import "../SubCss/Login.css";
import men from '../assets/images/PNG/men.png';
import women from '../assets/images/PNG/women.png';
import "../SubCss/loginpage.css";
import {NavLink,useNavigate,useLocation } from "react-router-dom";
export default function Login(param){

    const [showOTP, setOTPShow] = useState(false);
    const [showRegistration, setRegShow] = useState(false);
    const [showRegistration2, setReg2Show] = useState(false);
    const [otp, setOtp] = useState(null);
    const [mobile, setmobile] = useState('');
    const [referal,setreferalcode] = useState('');
    const [name, setName] = useState('');
    
    const [email, setEmail] = useState('');
    const [showTimer, setShowTimer] = useState(false);
    const [seconds, setSeconds] = useState(30); 
    const [gender,setGender] = useState('');
    const mobileInputRef = useRef(null);
    const firstInputRef = useRef(null);
    const [focusedIndex, setFocusedIndex] = useState(null);
    const [otperror,setOTPError] = useState('');
    const [referalError, setReferalError] = useState('');
    const [emailerror, setemailerror] = useState('');
    const [nameerror, setnameerror] = useState('');
     const [validated, setValidated] = useState(false);
   
      const hasError = validated && !mobile;
    
      const handleavatar = () => {
        setRegShow(true);
        setReg2Show(false);
      };
      const handleBack = () => {
        setRegShow(false); 
        setOTPShow(true); 
      };
      const handlefirst = () => {
        setOTPShow(false); 
      };
  useEffect(() => {
    let interval = null;
    if (showTimer && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      setShowTimer(false);
    }

    return () => clearInterval(interval);
  }, [showTimer, seconds]);
  const handleButtonClick = (selectedGender) => {
    setGender(selectedGender);
  };
  const [focusedGender, setFocusedGender] = useState('Male');

const handleFocus = (buttonGender) => {
  setFocusedGender(buttonGender);
};

const handleBlur1 = () => {
  setFocusedGender(null);
};

 

const getButtonStyles = (buttonGender) => {
  const isFocused = focusedGender === buttonGender;
  return {
    borderRadius: '50%', // Makes the button round
    border: `3px solid ${isFocused ? '#008EB0' : '#fff'}`, // Keep the border when clicked
    backgroundColor: '#fff', // White background
    color: '#008EB0',
    padding: '0',
    marginRight: '20px',
    marginTop: '20px',
    marginBottom: '20px',
    fontFamily: 'Inter',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: isFocused ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none', // Adds shadow when clicked
    transition: 'border 0.3s, box-shadow 0.3s', // Smooth transition on border and box-shadow changes
    cursor: 'pointer', // Pointer cursor on hover
};

};

  
  
  
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const handleNameChange1 = (e) => {
    const value = e.target.value;
    setName(value);

    // Clear the error message if the name is not empty
    if (value.trim() !== '') {
      setnameerror(''); // Clear error if the input is valid
    }
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email based on the pattern
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsValid(emailPattern.test(value));
    if (emailPattern.test(value)) {
      setemailerror(''); // Clear error if the input is valid
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
  }
  const GenerateOTPcall=async ()=> { 
    
    if(mobile === "" || mobile.length<10){
        console.log("Please enter Mobile")
    }else{
        setOTPError("");
        setOtp(null); 
        let GenerateOTPDatatemp = await GenerateOtp(mobile);
        if (GenerateOTPDatatemp.error === "0") { 
         
            setOTPShow(true); 
            setSeconds(30);
            setShowTimer(true);    
            if (firstInputRef.current) {
              firstInputRef.current.focus();
            }

        } 
        
    }
    setValidated(true);
    
  };

  const skipfunction=async ()=> { 
    
    window.location.href = "/";
  };
  const handleInputChange = (e) => {
    const input = e.target.value.toUpperCase();
    const regex = /^[A-Z]*$/; // Only allows uppercase letters

    if (regex.test(input)) {
      setreferalcode(input);
    }
    
  };
  const handleNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters

    // Validate the input
    if (value.length <= 10) {
      // if (value.length === 0 || /^[6-9]/.test(value)) {
        setmobile(value);
      // }
    }
  };
  const getInputStyle = (index) => {
    // Check if otp is defined and is a string
    const otpValue = otp ? otp[index] : '';
    const isFilled = otpValue !== undefined && otpValue !== '';
    const isFocused = index === focusedIndex;
    return {
      textAlign: "center",
      width: "50px",
      height: "50px",
      marginLeft: "10px",
      marginRight: "10px",
      borderRadius: "42px",
      border: isFocused ? "2px solid #008EB0" : "1px solid rgb(0 0 0 / 2%)", 
      outline: "none",
      fontSize: "24px",
      fontFamily: "Inter",
      backgroundColor: isFilled ? "#008EB0" : "#F2F2F2", // Blue background if filled
      color: isFilled ? "#fff" : "#000", // White text if filled
      transition: "background-color 0.3s", // Smooth transition
      caretColor:"transparent"
    };
  };
  const VerifyOTPcall=async (otpValue)=> { 
    setOtp(otpValue); 
    let  VerifyOTPDatatemp = await VerifyOtp(mobile,otpValue);
    if ( VerifyOTPDatatemp.error === "0") { 
         
        localStorage.setItem('is_guest', '0');
        localStorage.setItem('access_expiry_timestamp', VerifyOTPDatatemp.access_expiry_timestamp); 
        localStorage.setItem('access_token', VerifyOTPDatatemp.access_token);
        localStorage.setItem('refresh_token', VerifyOTPDatatemp.refresh_token); 
         
        // window.close();
        //  window.open("/");
      window.location.href = "/";
    } else if(VerifyOTPDatatemp.error === "2"){
    
      
        setRegShow(true); 
    }else if(VerifyOTPDatatemp.error === "1"){
        console.log("ereorewojowi");
        setOTPError("Please Enter a valid OTP")
    }
    
    // setRegShow(true);

};


const handleSubmit = (e) => {
    e.preventDefault();
    GenerateOTPcall();
};

//  const handleGoBack = () => {
//     navigate(-1); // Goes back to the previous page
//   };


const UserRegistrationCall = async () => {
  console.log("reg1");
  if (!name) {
    setnameerror('please enter your name');
    return;
  }

  if (!email || !isValid) {
    setemailerror('please enter valid email');
    return;
  }
  try {
    // Perform referral code verification
    const data = await verify_referral_code(referal);

    // Check if the referral code is valid
    if (data.error === "0") {
      // Show the next registration step if valid
      setReg2Show(true);
      
    } else {
      // Handle the case where the referral code is invalid
      setReferalError('Invalid referral code');
    }
  } catch (error) {
    // Handle errors from the API call
    console.error("An error occurred while verifying the referral code:", error);
    setReferalError('An error occurred. Please try again later.');
  }

  // For debugging: Log the current state
  console.log("showRegistration2", showRegistration2);
};


const UserRegistrationCall2=async ()=> {  
    
    let data = await UserRegistration(name,email,mobile,otp,"+91",gender,referal);
    if (data.error === "0") { 
      localStorage.setItem("access_expiry_timestamp", data.access_expiry_timestamp);
localStorage.setItem("access_token", data.access_token);
localStorage.setItem("refresh_token", data.access_token);
localStorage.setItem("is_guest", "0");
window.location.href = "/";
    } 
};


 const handleOTPChange = (otpValue) => {
    console.log("otpValue0:",otpValue);
    setOtp(otpValue);
    
    if (otpValue.length === 4) {
        VerifyOTPcall(otpValue); 
    }
  };
  

  useEffect(() => {
    // Focus on the mobile input when the page loads
    if (mobileInputRef.current) {
      mobileInputRef.current.focus();
    }
    // document.body.style.overflow = 'hidden';
  }, []);
 
  const popupRef = useRef(null);

  useEffect(() => {
    // Focus on the popup div when it becomes visible
    if (popupRef.current) {
      popupRef.current.focus();
    }
  }, []);
  const [isDesktop, setIsDesktop] = React.useState(window.innerWidth > 768);
  const navigate = useNavigate(); 
  const location = useLocation();
  React.useEffect(() => {
    const handleResize = () => {
    const isDesktopNow = window.innerWidth > 991;
    if (isDesktopNow !== isDesktop) {
    setIsDesktop(isDesktopNow);
    window.location.reload();
    }
    };
    
    window.addEventListener('resize', handleResize);
    
    
    return () => window.removeEventListener('resize', handleResize);
    }, [isDesktop]);


    return(
        <> 
     <div className="d-block d-lg-none d-xl-none" style={{ display: 'flex', flexDirection: 'column', height: '100vh',background:"white" }}>
  <div style={{display: 'flex', justifyContent: 'center' }}>
    
          <div className="no-gutters col-md-12 col-sm-12 col-xs-12"   style={{backgroundColor:"#001F2E",borderRadius:"0px", paddingLeft:"0 !important",
    paddingRight:"0 !important",display: 'inline-block',maxHeight:'max-content'}}>
                {!showRegistration && !showRegistration2 ? (
           <Col md={12}  style={{padding:0}}>
                <div className='imageContainer' style={{ overflow:"hidden"}}>
                
                <div
      style={{
        display: 'flex', // Container should be relative to place children absolutely
        justifyContent:'center',
        // display: 'inline-block', // Ensure the container fits the content
      }}
    >
      <img
        src={require("../assets/images/login.png")}
        alt="Slide 1"
        style={{
          maxWidth: "100%",
          display: 'block', // Ensures the image takes up the full width of the container
          width:"100%"
        }}
      />
      <Button
        className="skip"
        style={{
          position: 'fixed', // 
          top: '30px', 
          right: '30px', 
          borderRadius: "42px",
          fontSize:"15px",
          fontFamily: "Inter",
          backgroundColor: "#fff",
          color: "black",
          borderColor: "transparent",
          paddingLeft:"15px",
          paddingRight:"15px",
          paddingTop:"0px",
          paddingBottom:"0px",
        }}
        onClick={skipfunction}
      >
        Skip
      </Button>
    </div>
                    
                
                </div>
               
               
            </Col> ) : null}
            <Col lg={12} md={12} ref={popupRef} style={{ 
                backgroundColor:"white",
                padding: "0px"}}>
            
        <div className="text-light text-end" style={{marginRight:"30px",cursor:"pointer"}} onClick={()=>{param.clss()}} >
           
       </div>
            <div className='Loginform' style={{borderRadius: '42px 42px 42px 42px'}}>
                <Container>
                    <Row className="justify-content-center" style={{display:"contents"}}> 
                  
                        <Col >
                        
                            <Form className="d-flex flex-column">
                                {showOTP ? (
                                    showRegistration2 ?(
                                   
                                         <><Button
                                      className="order_status"
                                      type="button" 
                                      style={{
                                        borderColor: "transparent",
                                        backgroundColor: "white",
                                        color: "black",
                                        borderRadius: "5px",
                                        fontFamily: "Inter",
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                        padding: "20px 0px", // Add some padding for better spacing
                                        cursor: "pointer" // Show a pointer cursor on hover
                                      }}
                                      onClick={handleavatar} 
                                    >
                                      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="37.8753" height="37.8753" rx="18.9376" fill="white"/>
                                <path d="M16.632 26.6227C16.4415 26.6222 16.258 26.551 16.1171 26.4229L8.43131 19.5057C8.35206 19.4337 8.28874 19.3459 8.2454 19.2479C8.20207 19.15 8.17969 19.0441 8.17969 18.937C8.17969 18.8299 8.20207 18.724 8.2454 18.626C8.28874 18.5281 8.35206 18.4403 8.43131 18.3682L16.1171 11.4511C16.1904 11.3763 16.2783 11.3173 16.3753 11.2777C16.4723 11.2381 16.5764 11.2188 16.6811 11.2209C16.7858 11.223 16.889 11.2464 16.9844 11.2899C17.0797 11.3333 17.1651 11.3958 17.2355 11.4734C17.3058 11.5511 17.3595 11.6423 17.3932 11.7415C17.427 11.8406 17.4402 11.9457 17.4319 12.0501C17.4236 12.1545 17.394 12.2562 17.345 12.3487C17.296 12.4413 17.2286 12.5229 17.147 12.5885L10.0914 18.937L17.147 25.2854C17.2975 25.4222 17.3877 25.613 17.3977 25.8161C17.4078 26.0193 17.337 26.2181 17.2008 26.3691C17.1289 26.4487 17.0412 26.5124 16.9432 26.5561C16.8453 26.5998 16.7393 26.6225 16.632 26.6227Z" fill="black"/>
                                <path d="M28.9293 19.7071H8.94631C8.74247 19.7071 8.54698 19.6261 8.40284 19.482C8.25871 19.3378 8.17773 19.1423 8.17773 18.9385C8.17773 18.7347 8.25871 18.5392 8.40284 18.395C8.54698 18.2509 8.74247 18.1699 8.94631 18.1699H28.9293C29.1331 18.1699 29.3286 18.2509 29.4727 18.395C29.6169 18.5392 29.6978 18.7347 29.6978 18.9385C29.6978 19.1423 29.6169 19.3378 29.4727 19.482C29.3286 19.6261 29.1331 19.7071 28.9293 19.7071Z" fill="black"/>
                                </svg>
                                    </Button>
                                        <Container>  
                                          
                                    <Form.Group className="mb-3 mt-20" controlId="formBasicPhone">
                                        <Form.Label
                                          style={{
                                              color: "#023047",
                                              fontFamily: "Inter",
                                              
                                              fontSize: "25px",
                                              fontWeight: "bold",
                                              lineHeight: "1",
                                              display: "block",
                                              textAlign: "center",
                                              marginBottom: "15px",
                                          }}
                                      >
                                          {/* Register once<br></br> to stay <span style={{color: "#9CDCFC",borderBottom:"2px solid #FFCF0D"}}>fit forever</span> */}
                                          Choose Your Avatar
                                      </Form.Label>
                        
                                      </Form.Group>

                                      <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        <Button
  className="male"
  style={getButtonStyles('Male')}
  onFocus={() => handleFocus('Male')}
  onBlur={handleBlur1}
  onClick={() => handleButtonClick('Male')}
>
  <img src={men} alt="Male" style={{ borderRadius: '50%' }} />
</Button>

<Button
  className="female"
  style={getButtonStyles('Female')}
                                      onFocus={() => handleFocus('Female')}
                                      onBlur={handleBlur1}
                                      onClick={() => handleButtonClick('Female')}
>
  <img src={women} alt="Female" style={{ borderRadius: '50%' }} />
</Button>

        </div>
                                      
                                   

    
                                          <Button variant="primary" className="mt-10" style={{ backgroundColor:"#023047", color:"white",letterSpacing:"1px",fontSize:"20px",width: "100%",borderRadius:"42px",height:"50px",border:"none",fontFamily: "Inter",marginBottom:"20px"}} onClick={UserRegistrationCall2}>
                                          Continue
                                          </Button>
    

                                      </Container>
</>
                                       ):
                                       showRegistration?(
                                   <> <Button
                                    className="order_status"
                                    type="button" 
                                    style={{
                                      borderColor: "transparent",
                                      backgroundColor: "white",
                                      color: "black",
                                      borderRadius: "5px",
                                      fontFamily: "Inter",
                                      display: "flex",
                                      alignItems: "center", 
                                      padding: "20px 0px", 
                                      cursor: "pointer" 
                                    }}
                                    onClick={handleBack} 
                                  >
                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="37.8753" height="37.8753" rx="18.9376" fill="white"/>
                              <path d="M16.632 26.6227C16.4415 26.6222 16.258 26.551 16.1171 26.4229L8.43131 19.5057C8.35206 19.4337 8.28874 19.3459 8.2454 19.2479C8.20207 19.15 8.17969 19.0441 8.17969 18.937C8.17969 18.8299 8.20207 18.724 8.2454 18.626C8.28874 18.5281 8.35206 18.4403 8.43131 18.3682L16.1171 11.4511C16.1904 11.3763 16.2783 11.3173 16.3753 11.2777C16.4723 11.2381 16.5764 11.2188 16.6811 11.2209C16.7858 11.223 16.889 11.2464 16.9844 11.2899C17.0797 11.3333 17.1651 11.3958 17.2355 11.4734C17.3058 11.5511 17.3595 11.6423 17.3932 11.7415C17.427 11.8406 17.4402 11.9457 17.4319 12.0501C17.4236 12.1545 17.394 12.2562 17.345 12.3487C17.296 12.4413 17.2286 12.5229 17.147 12.5885L10.0914 18.937L17.147 25.2854C17.2975 25.4222 17.3877 25.613 17.3977 25.8161C17.4078 26.0193 17.337 26.2181 17.2008 26.3691C17.1289 26.4487 17.0412 26.5124 16.9432 26.5561C16.8453 26.5998 16.7393 26.6225 16.632 26.6227Z" fill="black"/>
                              <path d="M28.9293 19.7071H8.94631C8.74247 19.7071 8.54698 19.6261 8.40284 19.482C8.25871 19.3378 8.17773 19.1423 8.17773 18.9385C8.17773 18.7347 8.25871 18.5392 8.40284 18.395C8.54698 18.2509 8.74247 18.1699 8.94631 18.1699H28.9293C29.1331 18.1699 29.3286 18.2509 29.4727 18.395C29.6169 18.5392 29.6978 18.7347 29.6978 18.9385C29.6978 19.1423 29.6169 19.3378 29.4727 19.482C29.3286 19.6261 29.1331 19.7071 28.9293 19.7071Z" fill="black"/>
                              </svg>
                                  </Button>
                                    <Container style={{padding:"0px !important"}} > 
                                                      
                                    <Form.Group className="mb-3 mt-20" controlId="formBasicPhone">
                              <Form.Label
                                style={{
                                  color: "#023047",
                                  fontFamily: "'Inter', sans-serif",
                                  fontSize: "24px",
                                  fontWeight: "bold",
                                  lineHeight: "1",
                                  display: "block",
                                  textAlign: "center",
                                  marginBottom: "15px",
                                }}
                              >
                                  Let’s Be A Friend!
                                </Form.Label>
                                <Form.Label
                                  style={{
                                    color: "#494949",
                                    fontFamily: "'Inter', sans-serif",
                                    fontSize: "16px",
                                    lineHeight: "1",
                                    display: "block",
                                    textAlign: "center",
                                    marginBottom: "15px",
                                  }}
                                >
                                  Tell us About Yourself
                                </Form.Label>
                        
                                <Form.Control
                                  required
                                  type="text"
                                  value={name}
                                  placeholder="Enter your name"
                                  onChange={handleNameChange1}
                                  isInvalid={!!nameerror} 
                                  style={{
                                    paddingLeft: "15px",
                                    backgroundColor: "rgb(242, 242, 242)",
                                    border: "0px solid rgb(217, 217, 217)",
                                    borderRadius: "42px",
                                    height: "40px",
                                    fontSize: "15px",
                                    fontFamily: "'Inter', sans-serif",
                                    width: "100%",
                                    color: "rgb(0, 0, 0)",
                                    // marginBottom: "15px",
                                    padding: "0px !important",
                                  }}
                                  className="reg-placeholder"
                                />
                                 <Form.Control.Feedback type="invalid" style={{fontFamily: "Inter, sans-serif" ,display: "flex",paddingLeft:'5px', marginBottom:'15px'}}>
                                                  {nameerror}
                                                </Form.Control.Feedback>

                                        <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your email Id"
                                                value={email}
                                                onChange={handleEmailChange}
                                                onBlur={handleBlur}
                                                isInvalid={!!emailerror} 
                                                style={{
                                                  paddingLeft: '15px',
                                                  backgroundColor: 'rgb(242, 242, 242)',
                                                  border: '0px solid rgb(217, 217, 217)',
                                                  borderRadius: '42px',
                                                  height: '40px',
                                                  fontSize: '15px',
                                                  fontFamily: "'Inter', sans-serif",
                                                  width: '100%',
                                                  color: 'rgb(0, 0, 0)',
                                                  marginBottom: '5px',
                                                  padding: '0px !important',
                                                  // borderColor: !isValid && isTouched ? 'red' : 'transparent', 
                                                }}
                                                className="reg-placeholder"
                                                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                                title="Please enter a valid email address."
                                              />
                                           
                                           {/* {(!isValid || email === '') && isTouched && (
                                                <div style={{
                                                  marginTop: '5px',
                                                  fontSize: '12px',
                                                  color: 'red',
                                                  paddingLeft:'2px'
                                                }}>
                                                  Please enter a valid email address.
                                                </div>
                                              )}
                                         */}
                                         <Form.Control.Feedback type="invalid" style={{fontFamily: "Inter, sans-serif" ,display: "flex",paddingLeft:'5px'}}>
                                                  {emailerror}
                                                </Form.Control.Feedback>
                                                <Form.Control
                                                  type="text"
                                                  value={referal}
                                                  placeholder="Enter Referral Code (Optional)"
                                                  maxLength={6}
                                                  onChange={handleInputChange}
                                                  isInvalid={!!referalError} // Show feedback if there's an error
                                                  style={{
                                                  
                                                    backgroundColor: "rgb(242, 242, 242)",
                                                    border: "0px solid rgb(217, 217, 217)",
                                                    borderRadius: "42px",
                                                    height: "40px",
                                                    fontSize: "15px",
                                                    fontFamily: "'Inter', sans-serif",
                                                    width: "100%",
                                                    color: "rgb(0, 0, 0)",
                                                  
                                                    marginTop: "15px",
                                                    
                                                  }}
                                                  className="reg-placeholder"
                                                />
                                                <Form.Control.Feedback type="invalid" style={{fontFamily: "Inter, sans-serif" ,display: "flex",paddingLeft:'5px'}}>
                                                  {referalError}
                                                </Form.Control.Feedback>
                                              </Form.Group>



                                        <Button variant="primary" className="mt-10" style={{ backgroundColor:"#023047", color:"white",letterSpacing:"1px",fontSize:"20px",width: "100%",borderRadius:"42px",height:"50px",border:"none",fontFamily: "'Inter', sans-serif",marginBottom:"20px"}} onClick={UserRegistrationCall}>
                                        Continue
                                        </Button>

                                        {/* <p className="mt-3 text-center" style={{color:"#219EBC",fontFamily:"Inter",fontSize:"17px"}}>
                                    You may receive SMS updates from Nutsby and can opt out at any time.
                                    </p> */}
                                    </Container>
                                   </>
                                   ):

                                   <>
                                   { (
                                     <>
                                       <Button
                                         className="order_status"
                                         type="button"
                                         style={{
                                           borderColor: "transparent",
                                           backgroundColor: "white",
                                           color: "black",
                                           borderRadius: "5px",
                                           fontFamily: "Inter",
                                           display: "flex",
                                           alignItems: "center",
                                           padding: "20px 0px",
                                           cursor: "pointer"
                                         }}
                                         onClick={handlefirst}
                                       >
                                         <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                           <rect width="37.8753" height="37.8753" rx="18.9376" fill="white" />
                                           <path d="M16.632 26.6227C16.4415 26.6222 16.258 26.551 16.1171 26.4229L8.43131 19.5057C8.35206 19.4337 8.28874 19.3459 8.2454 19.2479C8.20207 19.15 8.17969 19.0441 8.17969 18.937C8.17969 18.8299 8.20207 18.724 8.2454 18.626C8.28874 18.5281 8.35206 18.4403 8.43131 18.3682L16.1171 11.4511C16.1904 11.3763 16.2783 11.3173 16.3753 11.2777C16.4723 11.2381 16.5764 11.2188 16.6811 11.2209C16.7858 11.223 16.889 11.2464 16.9844 11.2899C17.0797 11.3333 17.1651 11.3958 17.2355 11.4734C17.3058 11.5511 17.3595 11.6423 17.3932 11.7415C17.427 11.8406 17.4402 11.9457 17.4319 12.0501C17.4236 12.1545 17.394 12.2562 17.345 12.3487C17.296 12.4413 17.2286 12.5229 17.147 12.5885L10.0914 18.937L17.147 25.2854C17.2975 25.4222 17.3877 25.613 17.3977 25.8161C17.4078 26.0193 17.337 26.2181 17.2008 26.3691C17.1289 26.4487 17.0412 26.5124 16.9432 26.5561C16.8453 26.5998 16.7393 26.6225 16.632 26.6227Z" fill="black" />
                                           <path d="M28.9293 19.7071H8.94631C8.74247 19.7071 8.54698 19.6261 8.40284 19.482C8.25871 19.3378 8.17773 19.1423 8.17773 18.9385C8.17773 18.7347 8.25871 18.5392 8.40284 18.395C8.54698 18.2509 8.74247 18.1699 8.94631 18.1699H28.9293C29.1331 18.1699 29.3286 18.2509 29.4727 18.395C29.6169 18.5392 29.6978 18.7347 29.6978 18.9385C29.6978 19.1423 29.6169 19.3378 29.4727 19.482C29.3286 19.6261 29.1331 19.7071 28.9293 19.7071Z" fill="black" />
                                         </svg>
                                       </Button>
                                       <div className="text-center my-4">
                                         <Form.Group className="mb-3" controlId="formBasicPassword">
                                           <Form.Text
                                             style={{
                                               color: "#023047",
                                               fontFamily: "'Inter', sans-serif",
                                               fontSize: "24px",
                                               lineHeight: "29.05px",
                                               fontWeight: "400",
                                               caretColor: "transparent",
                                             }}
                                           >
                                             OTP Verification
                                           </Form.Text>
                                           <Form.Label
                                             style={{
                                               color: "#494949",
                                               fontFamily: "'Inter', sans-serif",
                                               fontSize: "16px",
                                               fontWeight: "400",
                                               lineHeight: "19px",
                                               marginBottom: "25px",
                                               marginTop: "20px"
                                             }}
                                           >
                                             OTP sent to +91{mobile}
                                           </Form.Label>
                                           <div className="justify-content-center mx-3">
                                             <OtpInput
                                               value={otp}
                                               onChange={handleOTPChange}
                                               numInputs={4}
                                               separator={<span>&nbsp;</span>}
                                               isInputNum={true}
                                               containerStyle={{ display: "flex", justifyContent: "center" }}
                                               shouldAutoFocus={true}
                                               inputType="number"
                                               renderInput={(props, index) => (
                                                 <input
                                                   {...props}
                                                   style={getInputStyle(index)}
                                                   onFocus={() => setFocusedIndex(index)}
                                                   onBlur={() => setFocusedIndex(null)}
                                                 />
                                               )}
                                             />
                                           </div>
                                         </Form.Group>
                                         <div>
                                           {otperror.length > 0 && (
                                             <Form.Text className="forgot" style={{ color: "red", fontSize: "13px", fontFamily: "'Inter', sans-serif" }}>
                                               Please Enter a valid OTP
                                             </Form.Text>
                                           )}
                                         </div>
                                         {showTimer ? (
                                           <Form.Text className="forgot" style={{ color: "#494949", fontSize: "18px", fontFamily: "'Inter', sans-serif" }}>
                                             Resend OTP in {seconds}s
                                           </Form.Text>
                                         ) : (
                                           <Form.Text className="forgot" style={{ color: "#494949", fontSize: "18px", fontFamily: "'Inter', sans-serif", textDecoration: "underline" }} onClick={GenerateOTPcall}>
                                             Resend OTP
                                           </Form.Text>
                                         )}
                                       </div>
                                     </>
                                   )}
                                 </>
                                 
                               
                                ) : 
                                (
                                <> {(
                                    <div style={{ textAlign: "center",borderRadius: '30px',  }}>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                      <Form.Group controlId="formBasicPhone">
                                        <Form.Label    className='nsstore' style={{
                                          color: "#023047",
                                          fontFamily: "'Inter', sans-serif",
                                        
                                          
                                          fontWeight: "bold",
                                          marginBottom: "10px",
                                          marginTop:"20px"
                                        }}>
                                          The All-In-One <br /> Nutrition Superstore
                                        </Form.Label>
                          <div>   <Form.Label className="mobile_number" style={{
                                          color: "#023047",
                                          fontFamily: "'Inter', sans-serif",
                                      
                                          fontWeight: "400",
                                          marginBottom: "20px"
                                        }}>
                                        Login with Mobile Number
                                        </Form.Label>
                                        </div>
                                     
                                        <div style={{ position: "relative", marginBottom: "20px" }}>
                                          <span style={{
                                            position: "absolute", 
                                            left: "15px", 
                                            top: hasError ? "32%" : "50%",
                                            transform: "translateY(-50%)", 
                                            color: "black", 
                                            fontSize: "16px"
                                          }}>+91</span>
                          
                                     <Form.Control
                                        required
                                        type="tel"
                                        className="custom-placeholder"
                                        value={mobile}
                                        placeholder="Enter the Mobile Number"
                                        title="Please provide a valid mobile number"
                                        onChange={handleNumberChange}
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            e.preventDefault(); 
                                            GenerateOTPcall();
                                            
                                          }
                                        }}
                                        ref={mobileInputRef} 
                                        style={{
                                          paddingLeft: "45px", 
                                          backgroundColor: "#F2F2F2",
                                          border: "0px solid #D9D9D9",
                                          borderRadius: "42px",
                                          height: "45px",
                                          fontSize: "16px",
                                          fontFamily: "'Inter', sans-serif",
                                          width: "100%",
                                          color: "#000",
                                        }}
                                      />

                                          <Form.Control.Feedback type="invalid" style={{fontFamily: "'Inter', sans-serif"}}>
                                            Please provide a valid mobile number
                                          </Form.Control.Feedback>
                                        </div>
                          
                                        <Button onClick={GenerateOTPcall}
                                         style={{
                                          backgroundColor: "#023047", 
                                          color: "#FFFFFF",
                                          fontSize: "17px",
                                          width: "100%",
                                          borderRadius: "42px",
                                          height: "45px",
                                          border: "none",
                                          fontFamily: "'Inter', sans-serif",
                                          marginBottom: "10px"
                                        }}>
                                          Continue
                                        </Button>
                          
                                        <p style={{
                                          color: "#7B7B7B", 
                                          fontFamily: "'Inter', sans-serif", 
                                          fontSize: "12px", 
                                          marginTop: "20px",
                                          marginBottom:"20px"
                                        }}>
                                          By Continuing, you agree to our <a href="/TermsandConditions" style={{color: "#023047", textDecoration: "underline"}}>Terms & Conditions</a>
                                        </p>
                                      </Form.Group>
                                    </Form>
                                  </div>)}</>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Col>
          </div>

          
        </div>
      </div>
     
        
        </>

    );
}