import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container,Row,Col,Button,Form,Card,Image,InputGroup,input} from 'react-bootstrap';

function ContactJS() {
    return(
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                <Container>
                <div className='about' style={{margin:"100px 0px"}}>
                    <h2 className='about_h2' style={{textAlign:"center", fontFamily:"Bebas"}}>REACH US</h2>
                    <section style={{marginBottom:"50px"}} id="reachus">
                        <div class="container">
                           
                            <div class="row"> 
                                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 contact-box">
                                    <div class="row contactbox1">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <div class="contact-icon">
                                                <i class="bi bi-geo-alt-fill"></i>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                                <div class="contact-info">
                                                    <h5>Corporate Office</h5>
                                                    <p>A-46, Madhura Nagar, Hyderabad, Telangana, India 500038.</p>
                                                    <div class="line"></div>
                                                </div>
                                        </div>
                                    
                                    </div>
                                    <div class="row contactbox1">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <div class="contact-icon">
                                                <i class="bi bi-envelope-fill"></i>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                                <div class="contact-info">
                                                    <h5>Email Id</h5>
                                                    <p> <a href="mailto:care@nutsby.com">care@nutsby.com</a></p>
                                                    <div class="line"></div>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="row contactbox1">
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <div class="contact-icon">
                                                <i class="bi bi-telephone-fill"></i>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                                <div class="contact-info" style={{borderBottom:"none"}}>
                                                    <h5>Contact</h5>
                                                    <p><a href="tel:9701823543" style={{color:"#858D9A"}}>+91 9701823543</a></p>
                                                </div>
                                        </div>
                                    </div>
                                
                                </div>
                                <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                    <div class="contact-form">
                                        <form class="form-wrap contact_forrrm" id="contact-form" method="POST">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="form-group">
                                                        <input type="text" name="name" id="name" placeholder="Enter your name" required="" />
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="form-group">
                                                        <input type="text" name="mobile" id="mobile" placeholder="Enter your mobile number" pattern="[1-9]{1}[0-9]{9}" maxlength="10" required="" />
                                                    
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="form-group">
                                                        <input type="email" name="mail" id="mail" required="" placeholder="Enter your email Id" />
                                                        <div class="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="form-group">
                                                        <input type="text" name="address" id="address" placeholder="Enter your address" required="" />
                                                    
                                                    </div>
                                                </div>
                                            
                                                
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <textarea name="msg" id="msg" maxlength="120" placeholder="Type your message" cols="10" rows="7" required=""></textarea>
                                                    
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <input type="hidden" name="g-recaptcha-response" value="" id="g-recaptcha-response" />
                                                    <button type="submit" id="con_form" class="btn style1" style={{float:"right"}} >
                                                        <i class="bi bi-send-fill"></i>
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                
                                </div>
                            </div>   
                                
                        </div>
                    </section>
                </div>

                </Container>

               
            </Col>
        </Row>
    )

}
export default ContactJS;