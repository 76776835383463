import { useEffect, useState } from "react";
import AllReviews from "../AllReviews";
import { ReviewListAPI } from "../../Service/ApiCalling";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

export default function ReviewsList(){
    const params = useParams();
    const [Product_id, setProduct_id] = useState(params.id);
    const [reviewlist, setReviewList] = useState([]);
    const ReviewList = async () => {  
        let data = await ReviewListAPI(Product_id);    
        if (data.error === "0") {
            setReviewList(data.reviews_list);
        }
      };


useEffect(()=>{
    ReviewList();
},[])

    return(
        
        <Row className="" style={{padding:"10px 10px"}}>
        {/* <Container lg={12} md={12} sm={12} xs={12} style={{marginLeft:"15px"}}>
        <h4 className="mb-2" style={{ fontFamily: "Inter" }}>Customer reviews</h4>
        </Container> */}
        {(reviewlist.length>0) ? 
                                <Col lg={12} md={12} sm={12} xs={12}>
                                 
                                {reviewlist.map((element, index) => (
                                    <Container>
                                        <AllReviews
                                        key={index}
                                        img={element.image}
                                        name={element.customer_name}
                                        review={element.review}
                                        rating={element.rating}
                                        feedback={element.title}
                                        date={element.created_datetime}
                                    />
                                    </Container>
                                ))}                                                                      
                                </Col>:<></>}
        </Row>
    );
}