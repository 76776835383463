 
import { Container, Row, Col, Button, Breadcrumb } from 'react-bootstrap';
import { NavLink,Link, useParams, useNavigate } from 'react-router-dom';
// import Overlay from 'react-bootstrap/Overlay';
import React,{ useState,useEffect } from 'react';
import { weightgainDataApi,weightlossDataApi,haircareDataApi,AddToCart,liverDataApi,digestionDataApi,skincareDataApi,eyecareDataApi,sleepsupportDataApi,jointhealthDataApi,stressreliefDataApi, NotifyMeApi, GetHeaderDetailsApi } from '../Service/ApiCalling';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; 
import SubscribeForm from "../MainComponents/SubscribeForm";
import DownloadTheApp from "../MainComponents/DownloadTheApp";
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import '../fonts/Inter-Regular.ttf'; 
import { DiscountIcon, EliteIcon } from "../MiniComponents/SvgIcons";
import ProductItemCard from '../MainComponents/SubComponents/MiniComponents/ProductItemCard';
import { useCart } from '../MainComponents/SubComponents/MiniComponents/CartContext';
import "../SubCss/goals.css";
import "../SubCss/subcategory.css";
import Slider from 'react-slick';
import { ShimmerDiv,ShimmerContentBlock, ShimmerTitle,ShimmerButton } from "shimmer-effects-react";
import MetaTags from '../MainComponents/Metatags';
import { ToastContainer, toast } from 'react-toastify';
import BottomBar from "./../MainComponents/SubComponents/Bottom";
export default function Subcategorypage() {
  
  const shimmeritems = Array.from({ length: 6 });
  const shimmeritems1 = Array.from({ length: 2 });
  const ess_shimmeritems = Array.from({ length: 4 })
  const ess_shimmeritems1 = Array.from({ length: 3 })
  const main_shimmeritems = Array.from({ length: 2 });
    const [essential,setessential] =useState([]);
    const [must_buy,setmustbuy] =useState([]);
    const [sliders, setsliders] = useState([]);
    const [top_grossing, settop_grossing] = useState([]);
    const [is_subscribed,setIsSubscribed] = useState('');
    const [main_goal,setmaingoal] =useState([])
    const [sub_goal,setsubgoal] =useState([])
    const { setCartCount } = useCart();
    const [addClicked,setAddClicked] = useState(null);
    const [notifyClicked,setNotifyClicked] = useState(null);
    const [productsLoading,setLoading] = useState(true);
    let history = useNavigate();
    const params = useParams();
    const [keyWordTag, setseKeywordtag] = useState([]);
    const [title_tag,setTitleTag] = useState("");
    const [meta_description_tag,setMetaDescriptionTag] = useState("");
    const [canonical_tag,setCanonicalTag] = useState("");
    const [og_title,setOGTitle] = useState("");
    const [og_url,setOGURL] = useState("");
    const [og_meta_description,setOGMetaDescription] = useState("");
    const [og_image,setOgImage] = useState("");
    const [alttagnames,setAltTagNames] = useState([]);
    const [altTags,setAltTags] = useState([]);
    const [banner,setbanner]= useState("");
    const [tag,setTag] = useState("");
    const [h1,setH1] = useState([]);
    const [h2,setH2] = useState([]);
    const [h3,setH3] = useState([]);
    const [alttext,setAltText] = useState("");


    const fetchInfo=async ()=> {  
        if(params.name==="hair-care"){
        
            let haircareDataApiData = await haircareDataApi();
            if (haircareDataApiData.error==="0") {  
                setessential(haircareDataApiData.cards)
                setsliders(haircareDataApiData.sliders);
                settop_grossing(haircareDataApiData.top_grossing);
                setmaingoal(haircareDataApiData.sub_main_goals);
                console.log(main_goal,"main_goals");
                setsubgoal(haircareDataApiData.sub_sub_goals);
                setmustbuy(haircareDataApiData.must_buy_supplements_cards);
                setLoading(false);
                setTitleTag("Top-Rated Hair Care Products for Perfect Hair | Nutsby");
                setMetaDescriptionTag("Explore a wide range of premium hair care products to keep your hair healthy, shiny, and strong. Shop top-rated solutions for all hair types at Nutsby.");
                setseKeywordtag(["Hair Care Products","Healthy Hair Solutions","Hair Strength and Shine","Hair Care Essentials","Best Hair Products"]);
                setCanonicalTag("https://www.nutsby.com/hair-care-supplements");
                setAltText("black haired men promoting hair care supplements");
                setOGTitle("Premium Hair Care Products for Healthier Hair");
                setOGURL("https://www.nutsby.com/hair-care");
                setOGMetaDescription("Find premium hair care products that ensure healthy, shiny, and strong hair. Shop top solutions tailored for every hair type at Nutsby.");
                setOgImage("https://www.nutsby.com/banner/hair-care.jpg");
            }else{
              setLoading(false);
            }
        }

        else if(params.name==="skin-care"){
            let skincareDataApiData = await skincareDataApi();
            if (skincareDataApiData.error==="0") {
                
             
                setessential(skincareDataApiData.cards);
                setsliders(skincareDataApiData.sliders);
                settop_grossing(skincareDataApiData.top_grossing);
                setmaingoal(skincareDataApiData.sub_main_goals);
                setsubgoal(skincareDataApiData.sub_sub_goals);
                setmustbuy(skincareDataApiData.must_buy_supplements_cards);
                setLoading(false);
                setTitleTag("Best Skin Care Products for Glow and Hydration | Nutsby");
                setMetaDescriptionTag("Explore a variety of skin care products designed to keep your skin radiant, hydrated, and healthy. Shop top-rated solutions for all skin types at Nutsby");
                setseKeywordtag(["Skin Care Products","Healthy Skin Solutions","Radiant Glow","Skin Hydration","Best Skin Care"]);
                setCanonicalTag("https://www.nutsby.com/skin-care-supplements");
                setAltText(" smooth skin women promoting skin care supplements");
                setOGTitle("Top Skin Care Products for Radiant and Healthy Skin");
                setOGURL("https://www.nutsby.com/skin-care");
                setOGMetaDescription("Find top skin care products for a radiant and healthy complexion. Shop essential solutions tailored for all skin types at Nutsby.");
                setOgImage("https://www.nutsby.com/banner/skin-care.jpg");
            }else{
              setLoading(false);
            }
        }
        
        else if(params.name==="eye-care"){
           
        let eyecareDataApiData = await eyecareDataApi();
        if (eyecareDataApiData.error==="0") {
            
            setessential(eyecareDataApiData.cards);
            setsliders(eyecareDataApiData.sliders);
            settop_grossing(eyecareDataApiData.top_grossing);
            setmaingoal(eyecareDataApiData.sub_main_goals);
            setsubgoal(eyecareDataApiData.sub_sub_goals);
            setmustbuy(eyecareDataApiData.must_buy_supplements_cards);
            setLoading(false);
            setTitleTag("Protect Your Eyes with the Best Eye Care Products | Nutsby");
                setMetaDescriptionTag("Find top eye care products designed to support healthy vision and eye health. Shop effective solutions for clear and protected eyes at Nutsby. ✅100% Authentic");
                setseKeywordtag(["eye supplements",
"eye care supplements",
"vitamins for eyesight",
"vitamins for eyes",
"eye vitamins",
"eye care products",
"eye care medicine",
"Eye Health Solutions",
"Eye Protection"]);
                setCanonicalTag("https://www.nutsby.com/eye-care");
                setAltText(" men promoting eye care supplements");
                setOGTitle("Essential Eye Care Products for Healthy Vision");
                setOGURL("https://www.nutsby.com/eye-care");
                setOGMetaDescription("Explore the best eye care products for maintaining healthy vision and eye protection. Shop top solutions for clear and healthy eyes at Nutsby.");
                setOgImage("https://www.nutsby.com/banner/eye-care.jpg");
            
        }else{
          setLoading(false);
        }
    }

    else if(params.name==="sleep-support"){
      let sleepsupportDataApiData = await sleepsupportDataApi();
      if (sleepsupportDataApiData.error==="0") {
          setessential(sleepsupportDataApiData.cards)
          setsliders(sleepsupportDataApiData.sliders);
          settop_grossing(sleepsupportDataApiData.top_grossing);
          setmaingoal(sleepsupportDataApiData.sub_main_goals);
          setsubgoal(sleepsupportDataApiData.sub_sub_goals);
          setmustbuy(sleepsupportDataApiData.must_buy_supplements_cards);
          setLoading(false);
          setTitleTag("Effective Solutions for Better Sleep | Nutsby");
                setMetaDescriptionTag("Discover top sleep support products designed to improve your rest and quality of sleep. Shop effective supplements and solutions for a better night's sleep at Nutsby.");
                setseKeywordtag(["strongest natural sleep aid",
"sleep support tablets",
"top 10 sleeping pills",
"sleep support supplement",
"natural sleeping gummies",
"natural sleeping pills in India",
"Sleep Improvement",
"Quality Sleep Aids",
"Better Sleep Solutions"]);
                setCanonicalTag("https://www.nutsby.com/sleep-support");
                setAltText("sleep healthy products");
                setOGTitle("Effective Solutions for Better Sleep | Nutsby");
                setOGURL("https://www.nutsby.com/sleep-support");
                setOGMetaDescription("Explore top sleep support products to enhance your rest and improve sleep quality. Shop effective solutions for a better night’s sleep at Nutsby.");
                setOgImage("https://www.nutsby.com/banner/sleep-support.jpg");
          
      }else{
        setLoading(false);
      }
    }
    else if(params.name==="stress-relief"){
      let stressreliefDataApiData = await stressreliefDataApi();
      if (stressreliefDataApiData.error==="0") {
          setessential(stressreliefDataApiData.cards)
          setsliders(stressreliefDataApiData.sliders);
          settop_grossing(stressreliefDataApiData.top_grossing);
          setmaingoal(stressreliefDataApiData.sub_main_goals);
          setsubgoal(stressreliefDataApiData.sub_sub_goals);
          setmustbuy(stressreliefDataApiData.must_buy_supplements_cards);
          setLoading(false);
          setTitleTag("Effective Solutions for Stress Relief | Nutsby");
                setMetaDescriptionTag("Explore top stress relief products designed to help you manage stress and promote relaxation. Shop effective solutions for a calm and balanced life at Nutsby");
                setseKeywordtag(["stress relief techniques",
"stress reliever pills",
"stress relief tablets",
"stress relief products",
"how to relieve stress quickly",
"stress relief medicine",
"how to reduce stress",
"Stress Relief Supplements"]);
                setCanonicalTag("https://www.nutsby.com/stress-relief");
                setAltText("A man in a maroon t-shirt holding his head with his fingers, promoting stress relief supplements");
                setOGTitle("Effective Solutions for Stress Relief | Nutsby");
                setOGURL("https://www.nutsby.com/stress-relief");
                setOGMetaDescription("Discover effective stress relief products to help you manage stress and enhance relaxation. Shop top solutions for a balanced and calm life at Nutsby.");
                setOgImage("https://www.nutsby.com/banner/stress-relief.jpg");
      }else{
        setLoading(false);
      }
    }
    else if(params.name==="digestion"){
      let digestionDataApiData = await digestionDataApi();
      if (digestionDataApiData.error==="0") {
          setessential(digestionDataApiData.cards)
          setsliders(digestionDataApiData.sliders);
          settop_grossing(digestionDataApiData.top_grossing);
          setmaingoal(digestionDataApiData.sub_main_goals);
          setsubgoal(digestionDataApiData.sub_sub_goals);
          setmustbuy(digestionDataApiData.must_buy_supplements_cards);
          setLoading(false);
          setTitleTag("Best Digestion Supplements Online | Buy Now | Nutsby");
                setMetaDescriptionTag("Find the best digestion supplements to improve your gut health. Shop premium products for better digestion at Nutsby. ✅100% Authentic");
                setseKeywordtag(["digestion syrup",
                  "supplements for digestion",
                  "natural supplements for digestion",
                  "herbal supplements for digestion",
                  "probiotic supplements for digestion",
                  "best digestion supplements in India",
                  "fast digestion supplements",
                  "digestion improvement supplements",
                  "how to improve digestive system",
                  "gut healthy supplements"]);
                  
                setCanonicalTag("https://www.nutsby.com/digestion-supplements");
                setAltText("A woman with brown hair holding her stomach, promoting digestion supplements");
                setOGTitle("Best Digestion Supplements Online | Buy Now | Nutsby");
                setOGURL("https://www.nutsby.com/digestion-supplements");
                setOGMetaDescription("Find the best digestion supplements to improve your gut health. Shop premium products for better digestion at Nutsby.");
                setOgImage("https://www.nutsby.com/banner/digestion.jpg");
      }else{
        setLoading(false);
      }
    }
    else if(params.name==="joint-health"){
      let jointhealthDataApiData = await jointhealthDataApi();
      if (jointhealthDataApiData.error==="0") {
          setessential(jointhealthDataApiData.cards)
          setsliders(jointhealthDataApiData.sliders);
          settop_grossing(jointhealthDataApiData.top_grossing);
          setmaingoal(jointhealthDataApiData.sub_main_goals);
          setsubgoal(jointhealthDataApiData.sub_sub_goals);
          setmustbuy(jointhealthDataApiData.must_buy_supplements_cards);
          setLoading(false);
          setTitleTag("Top-Rated Joint Health Products for Pain Relief | Nutsby");
                setMetaDescriptionTag("Find top joint health products designed to support pain relief, mobility, and overall joint care. Shop effective supplements for improved joint health at Nutsby.");
                setseKeywordtag([
                  "joint support supplements",
"joint health supplements",
"supplements for joint pain",
"joint health tablets",
"joint health vitamins",
"joint pain",
"joint pain supplements",
"joint pain medicine",
"how to improve joint health naturally",
                ]);
                setCanonicalTag("https://www.nutsby.com/joint-health");
                setAltText("A man holding his knees, promoting supplements.");
                setOGTitle("Best Joint Health Products for Pain Relief and Mobility");
                setOGURL("https://www.nutsby.com/joint-health");
                setOGMetaDescription("Explore effective joint health products for pain relief, improved mobility, and overall joint care. Shop top solutions for better joint health at Nutsby.");
                setOgImage("https://www.nutsby.com/banner/joint-health.jpg");
      }else{
        setLoading(false);
      }
    }
    else if(params.name==="liver-health"){
      let liverDataApiData = await liverDataApi();
      if (liverDataApiData.error==="0") {
          setessential(liverDataApiData.cards)
          setsliders(liverDataApiData.sliders);
          settop_grossing(liverDataApiData.top_grossing);
          setmaingoal(liverDataApiData.sub_main_goals);
          setsubgoal(liverDataApiData.sub_sub_goals);
          setmustbuy(liverDataApiData.must_buy_supplements_cards);
          setLoading(false);
          setTitleTag("");
                setMetaDescriptionTag("");
                setseKeywordtag([]);
                setCanonicalTag("");
                setAltText("");
                setOGTitle("");
                setOGURL("");
                setOGMetaDescription("");
                setOgImage("");
      }else{
        setLoading(false);
      }
    }
    else if(params.name==="weight-gain"){
      let weightgainDataApiData = await weightgainDataApi();
      if (weightgainDataApiData.error==="0") {
          setessential(weightgainDataApiData.cards)
          setsliders(weightgainDataApiData.sliders);
          settop_grossing(weightgainDataApiData.top_grossing);
          setmaingoal(weightgainDataApiData.sub_main_goals);
          setsubgoal(weightgainDataApiData.sub_sub_goals);
          setmustbuy(weightgainDataApiData.must_buy_supplements_cards);
          setLoading(false);
          setTitleTag("");
                setMetaDescriptionTag("");
                setseKeywordtag([]);
                setCanonicalTag("");
                setAltText("");
                setOGTitle("");
                setOGURL("");
                setOGMetaDescription("");
                setOgImage("");
      }else{
        setLoading(false);
      }
    }
    else if(params.name==="weight-loss"){
      let weightlossDataApiData = await weightlossDataApi();
      if (weightlossDataApiData.error==="0") {
          setessential(weightlossDataApiData.cards)
          setsliders(weightlossDataApiData.sliders);
          settop_grossing(weightlossDataApiData.top_grossing);
          setmaingoal(weightlossDataApiData.sub_main_goals);
          setsubgoal(weightlossDataApiData.sub_sub_goals);
          setmustbuy(weightlossDataApiData.must_buy_supplements_cards);
          setLoading(false);
          setTitleTag("");
                setMetaDescriptionTag("");
                setseKeywordtag([]);
                setCanonicalTag("");
                setAltText("");
                setOGTitle("");
                setOGURL("");
                setOGMetaDescription("");
                setOgImage("");
      }else{
        setLoading(false);
      }
    }
  
    
    }

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024, // Medium to large screens
          settings: {
            slidesToShow: 4, // Ensure 6 slides are shown even after 1024px
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600, // Small to medium screens
          settings: {
            slidesToShow: 2, // Show 5 slides on smaller screens
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480, // Very small screens
          settings: {
            slidesToShow: 2, // Show 3 slides on very small screens
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 300, // Tiny screens
          settings: {
            slidesToShow: 2, // Show 2 slides on tiny screens
            slidesToScroll: 1,
          },
        },
      ],
    };
    const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      appendDots: (dots) => (
        <div
          style={{
            position: 'relative',
            bottom: '-5px', // Adjust this to move the dots closer to the slider
            display: 'flex',
            justifyContent: 'center',
            paddingLeft:'0px'
          }}
        >
          <ul style={{ margin: '0px' }}>{dots}</ul>
        </div>
      ),
    };
const AddToCartCall=async (p_id,qnt,clicked,index,max_qty)=> { 
  if(clicked === true){
    setAddClicked(index);
  }
    // AddToCart 
    console.log(qnt)
    // if(qnt>0)
    //     {
  
            let addcartDatatemp = await AddToCart(p_id,qnt);
            if (addcartDatatemp.error === "0") {
              toast.success(addcartDatatemp.message, {
                autoClose: 3000,
              });
                // fetchInfo();  
                    const newList = top_grossing.map((item) => {
                        if (item.product_id === p_id) {
                          const updatedItem = {
                            ...item, 
                            cart_quantity: addcartDatatemp.cart_quantity,
  
                          };
                          setTimeout(function(){
                            setAddClicked(null);
                          },1300);
                  
                          return updatedItem;
                        } 
                        return item;
                      });
                  
                      settop_grossing(newList); 
                    // setcartCount(addcartDatatemp.items_count);
  
                    // const newupdatedItem = {
                    //     ...cartCalculationData,
                    //     items_total: addcartDatatemp.cart_price,
                    //     total_price: addcartDatatemp.grand_total,
                    //     total_price: addcartDatatemp.grand_total_raw,
  
                    //   };
                    //   setcartCalculationData(newupdatedItem);
                // (e) =>setdetailsData((prevState) => ({ ...prevState, [e.target.name]: 1 }));
                // this.setState( detailsData.name="AKash");
          
              const headerData = await GetHeaderDetailsApi();
              if (headerData.error === "0") {
                if(headerData.cart_count!=null){
                  setCartCount(headerData.cart_count);
                }else{
                  setCartCount(0);
                }
                
                console.log(headerData.cart_count,"khfaosdufhuafs") 
              }
            }
      
   
  };

  const NotifyMeCall = async (p_id, type,clicked,index) => {
    if(clicked === true){
      setNotifyClicked(index);
    }
    try {
      let Data = await NotifyMeApi(p_id, type);
      if (Data.error === "0") {
        if (type === "stop") {
          Data.is_notified = "0";
          console.log("is_stopped");
        } else {
          Data.is_notified = "1";
          console.log("is_notified");
          toast.success(Data.message, {
            autoClose: 3000,
          });
        }
        const newList = top_grossing.map((item) => {
          if (item.product_id === p_id) {
            const updatedItem = {
              ...item,
              is_notified: Data.is_notified,
            };
            setTimeout(function(){
              setNotifyClicked(null);
            },1100);
            return updatedItem;
          }
          return item;
        });
        settop_grossing(newList);
      } else {
        console.log(Data.error);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

useEffect(() => {
  
  setIsSubscribed(localStorage.getItem("is_subscribed"));
  // alert(params.name,'this the param value')
  console.log("id passed",params);
    fetchInfo(); 
  }, []);


const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  
  const productsresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    minidesktop: {
      breakpoint: { max: 1495, min: 1200 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1200, min: 900 },
    items: 4,
  },
  minitablet: {
    breakpoint: { max: 900, min: 464 },
  items: 3,
},
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3, 
  },
}; 

  const productsresponsivesss = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2, 
    },
  };

 
  
  const imageMap = {
    "hair-care": require("../assets/images/HairCare_banner.png"),
    "skin-care": require("../assets/images/main_banner_skin.png"),
    "eye-care": require("../assets/images/main_banner_eye.png"),
    "sleep-support": require("../assets/images/sleep_banner_new.png"),
    "stress-relief": require("../assets/images/main_banner_stress.png"),
    "digestion": require("../assets/images/main_banner_digestion.png"),
    "joint-health": require("../assets/images/joint_health_banner_new.png"),
    "liver-health": require("../assets/images/liver_main_banner_new.png"),
    "weight-gain": require("../assets/images/main_banner_weight_gain.png"),
    "weight-loss": require("../assets/images/main_banner_weight_loss.png"),
   
  };
  const selectedImage = imageMap[params.name];


  const imageMap2 = {
    "hair-care": require("../assets/images/hair_care_banner_mobile.png"),
    "skin-care": require("../assets/images/skin_care_banner_mobile.png"),
    "eye-care": require("../assets/images/eye_banner_mobile.png"),
    "sleep-support": require("../assets/images/sleep_banner_mobile_new.png"),
    "stress-relief": require("../assets/images/stress_relief_banner_mobile.png"),
    "digestion": require("../assets/images/digestion_banner_mobile.png"),
    "joint-health": require("../assets/images/joint_banner_mobile.png"),
    "liver-health": require("../assets/images/liver_banner_mobile.png"),
    "weight-gain": require("../assets/images/weight_gain_banner_mobile.png"),
    "weight-loss": require("../assets/images/weight_loss_banner_mobile.png"),
   
  };
  const selectedImage2 = imageMap2[params.name];




  const breadcrumbMap = {
    "hair-care": "Hair Care",
    "skin-care": "Skin Care",
    "eye-care": "Eye Care",
    "sleep-support": "Sleep Support",
    "stress-relief": "Stress Relief",
    "digestion": "Digestion",
    "joint-health": "Joint Health",
    "liver-health": "Liver Health",
    "weight-loss": "Weight Loss",
    "weight-gain": "Weight Gain",
  };
  
  const selectedBreadcrumb = breadcrumbMap[params.name];
  
if(!productsLoading){
  return (
    <>
     
     <MetaTags
      
      title={title_tag}
      description={meta_description_tag}
      keywords={keyWordTag}
      canonical={canonical_tag}
      url="https://www.nutsby.com"
      image="https://www.nutsby.com/images/logo.jpg" 
      alt={alttext}
      ogTitle={og_title}
      ogUrl={og_url}
      ogDescription={og_meta_description}
      ogImage={og_image}
      schema={{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Nutsby Fitcare LLP",
        "url": "https://www.nutsby.com",
        "logo": "https://www.nutsby.co/logo.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91-1234567890",
          "contactType": "Customer Service",
        },
      }}
    />

{
    <div> 
         

        <section >
            <div onClick={()=>{}} >
              <div className="mainCarousel d-none d-md-block d-lg-block container" style={{paddingBottom: "0px",paddingTop: "7rem",borderRadius:'30px'}} > 
              <Row >
                    <Col lg={12} md={12} sm={12} col={12}  >
                      
                          {/* {(params.id==="1") ? 
                          <img src={require("../assets/images/HairCare_banner.png")} alt="" style={{ borderRadius:'20px'}}/>
                          :((params.id==="2") ? <img src={require("../assets/images/Athlete.png")} alt="" style={{ borderRadius:'20px'}} />:
                          ((params.id==="3") ? <img src={require("../assets/images/Happy_healthy.png")} alt="" style={{ borderRadius:'20px'}} />:
                          ((params.id==="4") ? <img src={require("../assets/images/fitness_forever.png")} alt=""  style={{ borderRadius:'20px'}}/>:
                          (params.id === "5") ? <img src={require("../assets/images/PNG/stress_relief.png")} alt="" style={{ borderRadius:'20px'}}/> :
                          (params.id === "6") ? <img src={require("../assets/images/PNG/digestion.png")} alt="" style={{ borderRadius:'20px'}}/> :
                          (params.id === "7") ? <img src={require("../assets/images/PNG/newjointhealth.png")} alt="" style={{ borderRadius:'20px'}}/> :
                          (params.id === "8") ? <img src={require("../assets/images/PNG/newliverhealth.png")} alt="" style={{ borderRadius:'20px'}}/> :
                          (params.id === "9") ? <img src={require("../assets/images/PNG/weight.png")} alt="" style={{ borderRadius:'20px'}}/> :
                          (params.id === "10") ? <img src={require("../assets/images/PNG/weightloss.png")} alt="" style={{ borderRadius:'20px'}}/> :
                          "")))}  */}
                          {selectedImage ? (
                            <img src={selectedImage} alt="" style={{ borderRadius:'20px',webkitUserDrag:"none" }} />
                          ) : <></>}
                            
                      </Col>                    
                  </Row> 
              </div>
              <div className="mainCarousel d-block d-md-none d-lg-none d-sm-block container" style={{paddingBottom: "0px",paddingTop: "5em",webkitUserDrag:"none"}} > 
                  <Row>
                  <Col lg={12} md={12} sm={12} col={12}>
                  {/* {(params.id==="1") ? <img src={require("../assets/images/PNG/mb2.png")} alt=""  style={{ borderRadius:'20px'}}/>:
                  ((params.id==="2") ? <img src={require("../assets/images/Athlete.png")} alt="" style={{ borderRadius:'20px'}} />:
                  ((params.id==="3") ? <img src={require("../assets/images/Happy_healthy.png")} alt=""  style={{ borderRadius:'20px'}}/>:
                  ((params.id==="4") ? <img src={require("../assets/images/fitness_forever.png")} alt="" style={{ borderRadius:'20px'}}/>:
                  (params.id === "5") ? <img src={require("../assets/images/PNG/stress_relief.png")} alt="" style={{ borderRadius:'20px'}}/> :
                  (params.id === "6") ? <img src={require("../assets/images/PNG/digestion.png")} alt="" style={{ borderRadius:'20px'}}/> :
                  (params.id === "7") ? <img src={require("../assets/images/PNG/newjointhealth.png")} alt="" style={{ borderRadius:'20px'}}/> :
                  (params.id === "8") ? <img src={require("../assets/images/PNG/newliverhealth.png")} alt="" style={{ borderRadius:'20px'}}/> :
                  (params.id === "9") ? <img src={require("../assets/images/PNG/weight.png")} alt="" style={{ borderRadius:'20px'}}/> :
                  (params.id === "10") ? <img src={require("../assets/images/PNG/weightloss.png")} alt="" style={{ borderRadius:'20px'}}/> :
                  "")))}  */}
                  {selectedImage2 ? (
                            <img src={selectedImage2} alt="" style={{ borderRadius:'18px' }} />
                          ) : <></>}
                        
                      </Col>                      
                  </Row> 
              </div>  
             
             <Row className='d-block d-md-none d-lg-none d-sm-none'>
              <Col xs={12} sm={12} md={8} lg={8} className='breadcrumb' style={{marginBottom:"0px",}}>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="#"> Home</Breadcrumb.Item>
                    {
                    (params.id==="1") ?  <Breadcrumb.Item href="#">
                      Muscle Building
                    </Breadcrumb.Item>:
                    ((params.id==="2") ?  <Breadcrumb.Item href="#">
                      Athlete journey
                    </Breadcrumb.Item>:
                    ((params.id==="3") ?  <Breadcrumb.Item href="#">
                      Happy & Healthy
                    </Breadcrumb.Item>:
                    ((params.id==="4") ?  <Breadcrumb.Item href="#">
                     Fitness Forever
                    </Breadcrumb.Item>:
                    
                    
                    "")))} 
                   
                  </Breadcrumb> */}
                   <Breadcrumb>
                    <Breadcrumb.Item
                    role='button'
                    
                    onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}
                    > Home </Breadcrumb.Item>
                    {selectedBreadcrumb ? (
                      <Breadcrumb.Item href="#"> {selectedBreadcrumb} </Breadcrumb.Item>
                    ) : <></>}
                  </Breadcrumb>
                </Col>
                </Row> 

                <div className='d-none d-md-block d-lg-block d-sm-block container'>
              <Col  className='breadcrumb' style={{marginBottom:"0px"}}>
              <Breadcrumb>
                    <Breadcrumb.Item onClick={() => {
                      
                      console.log("Breadcrumb clicked");
                      window.location.href = `/`;
                    }}> Home </Breadcrumb.Item>
                    {selectedBreadcrumb ? (
                      <Breadcrumb.Item href="#"> {selectedBreadcrumb} </Breadcrumb.Item>
                    ) : <></>}
                  </Breadcrumb>
                </Col>
                </div> 
                </div>
                
           

          </section>  

  {/*<!--second section --->*/}
  <section className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" ,marginBottom:"20px"}}>
  {/* Heading */}
  <h4 style={{ textAlign: "center", margin: window.innerWidth >= 576 ?"20px 0":"10px 0px" }}>Essential Supplements</h4>

  {/* Content */}
  <div className="container" style={{ cursor: "pointer", padding: '0px 10px' }}>
    <Row noGutters>
      {essential.length > 0 && essential.map((item, index) => (
        <Col lg={3} md={3} sm={6} xs={6}  key={index} style={{ padding: '5px', textAlign: 'center' }}>
          <NavLink className="cardCollection"to={`/${item.url_title}/Collection/${item.hash_id}`}>
            <div className="image-container" style={{ overflow: "hidden", margin: 0 }}>
              <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </div>
            {/* Name below the image */}
            <h6 className='musttry' style={{ marginTop: '10px', color: '#333', display: 'flex', justifyContent: 'center',textAlign:'center' }}>
                  {item.name}
                </h6>
          </NavLink>
        </Col>
      ))}
    </Row>
  </div>
</section>

 {/*<!--third section --->*/}
 <div>
 <div>
  <section className='container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px', padding: '0,10px' }}>
    <div className=''>
      {main_goal && main_goal.length > 0 && (
             <NavLink className="cardCollection"  to={`/${main_goal[0].url_title}/Collection/${main_goal[0].hash_id}`}>
            
        <div className="image-container mb-1" style={{ flex: '1', padding: '5px' }}>
          <img src={ window.innerWidth >= 576?main_goal[0].web_image:main_goal[0].image} alt="Sub Main Goal 1" style={{ width: '100%', height: '100%' }} />
        </div></NavLink>
      )}
    </div>
  </section>
</div>


</div>

       

{/* <!-- fourth section --> */}


{/* <!-- fifth section --> */}
<section className="container" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <Col style={{ cursor: "pointer", padding: '0px 10px' }}>
      <Row noGutters>
        {sub_goal.length > 0 && sub_goal.map((item, index) => (

          (sub_goal.length===2?<Col key={index} xs={12} sm={12} md={6} lg={6}  style={{ padding: '5px' }}>
            <NavLink className="cardCollection" to={`/${item.url_title}/Collection/${item.hash_id}`}>
              <div className="image-container" style={{ overflow: "hidden", position: "relative", margin: 0 }}>
                <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                
               
              </div>
            </NavLink>
          </Col>:<Col key={index} xs={6} sm={6} md={3} lg={3}  style={{ padding: '5px' }}>
          <NavLink className="cardCollection" to={`/${item.url_title}/Collection/${item.hash_id}`}>
              <div className="image-container" style={{ overflow: "hidden", position: "relative", margin: 0 }}>
                <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                
               
              </div>
            </NavLink>
          </Col>)
        ))}
      </Row>
    </Col>
  </section>
  {/* <section className="container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "20px",padding:"5px" }}>
      <div  style={{ paddingBottom: "20px", width: '100%' ,height:'100%'}}>
        <Row style={{ margin: 0 }}>
          <Col md={12} lg={12} sm={12} xs={12} style={{ paddingLeft:'2px'}}>
            <Slider
              {...settings1}
            >
              {sliders && sliders.length > 0 ? (
                sliders.map((slider, index) => (
                  <NavLink className="cardcategory" to={`/Products/Brand/${slider.ref_id}`}>
                  <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '99%' }}>
                   
                    <img
                      src={slider.image}
                      alt={`Slider ${index + 1}`}
                      style={{ width: '99%', height: '100%' }}
                    />
                  </div></NavLink>
                 
                ))
              ) : (
                <div>No slides available</div> // Debugging message
              )}
            </Slider>
          </Col>
        </Row>
      </div>
    </section> */}

  {/* <!-- Fourth section --> */}
  <section className="container" style={{marginTop:'20px'}} >
       
        <Row>      
            <Col md={12}>
                <div className="newarrivalproducts" > 
                <h4 style={{ textAlign: "start", margin: "10px 5px 5px 5px" }}>Top Grossing Products</h4>
                </div>
            </Col>
        </Row>  

        <div style={{  paddingBottom: "20px"}}>
        <div className="container1">
          <Carousel autoPlay={true} arrows={false} responsive={productsresponsive} draggable={true} infinite={true} className="stayfit1">   

                { top_grossing.map((element) =>
                                <Col style={{padding:"2px"}}> 
                                 <ProductItemCard
                                   url_title={element.url_title}
                                    add_cart={AddToCartCall}
                                    image={element.image}
                                    rate={element.average_rating}
                                    dis={element.discount_percentage}
                                    name={element.name}
                                    tag={element.item_tag}
                                    elite_price={element.elite_price}
                                    saleprice={element.price}
                                    mrp={element.mrp}
                                    cart_qnt={element.cart_quantity}
                                    id={element.product_id}
                                    notified={element.is_notified}
                                    max_qnty={element.max_cart_quantity}
                                    nuin={element.nuin}
                                    stock={element.stock}
                                    notify_me = {NotifyMeCall}
                                    is_subscribed = {is_subscribed}
                                    clicked = {addClicked === element.product_id}
                                    notifyClicked = {notifyClicked === element.product_id}
                                    index = {element.product_id}
                                    /> 
                               
                                </Col> 
  )}
              
                                  
            </Carousel> </div>
        </div> 
      
  </section>
  <section className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" ,marginBottom:"30px",marginTop:"20px"}}>
      {/* Heading */}
      <h4 style={{ textAlign: "center", margin: window.innerWidth >= 576 ?"10px 0":"10px 0px" }}>Must Buy Supplements</h4>

      {/* Carousel */}
      <div className="container" style={{ cursor: "pointer", padding: '5px 20px' }}>
        <Slider {...settings}>
          {must_buy.length > 0 && must_buy.map((item, index) => (
            <div key={index} style={{ padding: '5px' }}>
              <NavLink className="cardCollection" to={`/${item.url_title}/Collection/${item.hash_id}`}>
                <div className="image-container" style={{ overflow: "hidden", margin:'3px' }}>
                  <img src={item.web_image} alt={item.name} style={{ width: "100%", height: "100%",  padding:'10px 0px 10px 8px'}} />
                </div>
                <h5 className='musttry' style={{ marginTop: '10px', color: '#333', display: 'flex', justifyContent: 'center',textAlign:'center' }}>
                  {item.name}
                </h5>
              </NavLink>
            </div>
          ))}
        </Slider>
      </div>
    </section>
    <ToastContainer />
    <BottomBar/>
   </div>
   }
    </>
  );
}else{
  return (
    <section>
    <Container className="mt-100 d-none d-md-block d-lg-block" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
    <Row className="mt-10">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto"  style={{borderRadius:"10px"}}/>
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={12} sm={6} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        
      
    
      <Row className="mt-20" >
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={190} width="auto" style={{borderRadius:"10px"}} />
  </Col>
    </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
      
        <Row className="mt-20">
        {shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
        </Col>
        ))}
        </Row>
      
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={190} width="auto" />      
        </Col>
        ))}
        </Row>  
      </Col>
    </Row>
</Container>
<Container className="mt-80 d-block d-md-none d-lg-none d-sm-block" style={{paddingBottom: "20px",height:"",alignContent:"center"}}>
<Row className="mt-10">
      <Col xs={12} sm={12} md={12} lg={12}>
    
      <Row className="mt-20">
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={180} width="auto"  style={{borderRadius:"10px"}}/>
  </Col>
    </Row>

    
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
        <Row className="mt-20 display-flex justify-content-center">
        {main_shimmeritems.map((_, index) => (
        <Col xs={12} sm={12} md={6} lg={6} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
        
      
    
      <Row className="mt-20" >
      <Col xs={12} sm={12} md={12} lg={12}>
    <ShimmerDiv mode="light" height={180} width="auto" style={{borderRadius:"10px"}} />
  </Col>
    </Row>
        <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems.map((_, index) => (
        <Col xs={6} sm={6} md={3} lg={3} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={140} width="auto" />      
        </Col>
        ))}
        </Row>  
      
        <Row className="mt-20">
        {shimmeritems1.map((_, index) => (
        <Col xs={6} sm={6} md={2} lg={2}  style={{padding:'0px'}}>
        <ShimmerContentBlock mode="light" rounded={1} items={1} itemsGap={20} thumbnailHeight={300} thumbnailWidth={300} thumbnailRounded={1} contentDetailsPosition="start" contentDetailTextLines={2} />
        </Col>
        ))}
        </Row>
      
    <Row className="mt-20 display-flex justify-content-center">
        {ess_shimmeritems1.map((_, index) => (
        <Col xs={4} sm={4} md={4} lg={4} style={{marginBottom:"10px"}}>
        <ShimmerDiv mode="light" rounded={1} height={120} width="auto" />      
        </Col>
        ))}
        </Row>  
       
      </Col>
    </Row>
</Container>
</section>
);
}
  
}
 
