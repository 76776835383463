import React, { useEffect, useState } from "react";
import {Container, Row, Col,Modal,Image, Offcanvas,Card} from 'react-bootstrap'; 
import { 
    NavLink,
    useNavigate,
} from "react-router-dom";
import {AddToCart} from '../../../Service/ApiCalling';
import Button from 'react-bootstrap/Button';
import "../../../SubCss/CartModal.css"; 
import  {DiscountIcon,EliteIcon,NotifyMe,StopNotify,AddtoCartSVG, NewAddToCartIcon, NewNotifyMeIcon, EliteFullLogoIcon, EliteNewInfoIcon} from "../../../MiniComponents/SvgIcons";  
import Lottie from "lottie-react";
import AddtoCart from "../../../assets/lottie/add-to-cart.json"
import NotifiyCall from "../../../assets/lottie/bell-ringed.json"
export default function ProductItemCard(params) {

  const [maxqnty,setMaxQty] = useState(params.max_qnty);

  const navigate = useNavigate()
      const gotToNewPagesss = () => {
        // navigate("/membership_details");
        window.location.href = `/membership_details`;
      }
    
    return (
        <>
        <div className="border-0 bg-white  " style={{borderRadius:"10px"}}>
                    <div className="productcard-item" style={{border:"solid 1px #D1D1D1",borderRadius:"10px"}}>
                      <div className="productimage-conatiner" style={{backgroundColor:"#f2f2f2",borderRadius:"10px",margin:"10px",padding:"5px",}}>
                    
                    

                      <div className="d-flex justify-content-between " style={{width:'auto'}}>
  {params.dis === "0" ? (
    <div
      className="discount"
      style={{
        backgroundColor: "transparent",
        color: "transparent",
        padding: "3px 10px",
        borderRadius: "25px",
       
      }}
    >
      <p style={{ height: "6px" }}></p>
    </div>
  ) : (
    <div
      className="discount"
      style={{
        backgroundColor: "#CAFFE2",
        color: "black",
        borderRadius: "25px",
        padding: "3px 10px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <DiscountIcon />
      <span style={{ fontSize: "13.5px", padding: "2px 3px", }}>
        {Math.round(params.dis)}% off
      </span>
    </div>
  )}

  {params.rate === "0.0" ? (
    <div
      className="discount"
      style={{
        width: "auto", 
        height: "auto", 
      }}
    ></div>
  ) : (
    <div
      className="discount custom"
      style={{
       
        backgroundColor: "#FFF4CA",
        padding: "3px 10px",
        borderRadius: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        
        
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="18"
        fill="#FFCF0D"
        className="bi bi-star-fill"
        viewBox="0 0 16 22"
      >
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
      </svg>
      <span style={{color:"black",fontSize: "13.5px",padding: "2px 3px", paddingTop:'1px'}}>{params.rate}</span>
    </div>
  )}
</div>


                    <div >
                      {/* <NavLink reloadDocument to={`/product_details/1/sfasdfasdasfasd`}> */}
                      <a href={`/${encodeURIComponent(params.url_title)}/p/${encodeURIComponent(params.nuin)}`} style={{display:"flex", justifyContent:"center"}}>
                        
                        <img className="img-fluid " src={params.image} alt={params.alt_text} style={{ borderRadius: "20px",maxWidth:'80%' }} />
                      
                      </a>
                    </div>

                    

                  </div>

                  <Row className="mt-10 mb-2 d-flex align-content-end">
                      
                        <Col lg={12} md={12} sm={12} xs={12}> 

                        
                          {
                          (Number(params.stock)>0)?
                          (params.clicked ?
                          //     <Container className="cart-inc-btn-radius" style={{width:"45px",height:"45px",borderRadius:"50px"}}>
                          //     <Row>
                                  
                          //         <Col>
                          //         <Button className="cart-inc-btn" variant="transparent" onClick={() =>  params.add_cart(params.id,(Number(params.cart_qnt)+1))}>
                          //           {params.cart_qnt}
                                    
                          //           </Button>
                          //         <Lottie animationData={AddtoCart}/>
                          //         </Col>
                                  
                          //     </Row>
                          // </Container>
                          <div className="parent-container d-flex justify-content-end" style={{width:"97%",height:"16px",alignItems:"flex-end",marginLeft:"-6px",marginBottom:"-10px"}}>
                          <div className="cart-btn" style={{backgroundColor:"#023047",textAlign:" -webkit-center",color:" white",borderRadius: "35px",marginLeft: "5px",padding: "2px",marginRight: "5px"}} >
                          {/* onClick={() => AddToCartCall(data.product_id, (Number(data.cart_quantity) + 1))} */}
                              {/* Add */}
                              <Lottie animationData={AddtoCart} style={{height:"55px",width:"55px"}} />
                            
                          </div>
                        </div>
                              
                              :
                            <div className="parent-container d-flex justify-content-end" style={{width:"97%",height:"16px",alignItems:"flex-end",marginLeft:"-6px",marginBottom:"-10px"}}>
                              <div className="cart-btn" style={{backgroundColor:"#023047",textAlign:" -webkit-center",color:" white",borderRadius: "35px",marginLeft: "5px",padding: "2px",marginRight: "5px"}}  onClick={() =>  params.add_cart(params.id,(Number(params.cart_qnt)+1),true,params.index,params.max_qnty)}>
                              {/* onClick={() => AddToCartCall(data.product_id, (Number(data.cart_quantity) + 1))} */}
                                  {/* Add */}
                              <NewAddToCartIcon />
                                
                              </div>
                            </div>
                            ):
                            
                          ( Number(params.notified)==1 && params.notifyClicked?
                          <div className="parent-container d-flex justify-content-end" style={{width:"97%",height:"16px",alignItems:"flex-end",marginLeft:"-6px",marginBottom:"-10px"}}>
                            <div className="cart-btn" style={{backgroundColor:"#023047",textAlign:" -webkit-center",color:"white",
                              fontWeight:"400",
                              borderRadius: "35px",marginLeft: "5px",padding: "2px",marginRight: "5px"}}  onClick={() => params.notify_me(params.id,"stop",true,params.index)}>
                            {/* onClick={() => AddToCartCall(data.product_id, (Number(data.cart_quantity) + 1))} */}
                                
                              
                              <Lottie animationData={NotifiyCall} style={{width:'50px',height:"50px"}}/>
                            </div>
                          </div>:
                            <div className="parent-container d-flex justify-content-end" style={{width:"97%",height:"16px",alignItems:"flex-end",marginLeft:"-6px",marginBottom:"-10px"}}>
                              <div className="cart-btn" style={{backgroundColor:"#023047",textAlign:" -webkit-center",color:"white",
                                  fontWeight:"400",
                                borderRadius: "35px",marginLeft: "5px",padding: "2px",marginRight: "5px"}}  onClick={() => params.notify_me(params.id,"",true,params.index)}>
                              {/* onClick={() => AddToCartCall(data.product_id, (Number(data.cart_quantity) + 1))} */}
                                  
                                <NewNotifyMeIcon />
                                
                              </div>
                            </div>)
                          }

                        </Col>
                      
                      </Row>
                  <div className="bottom-container" >
                  <Container style={{paddingBottom:"10px"}}>
                    <div   >
                      {/* <NavLink */}
                    <NavLink reloadDocument
                        
                        to= {`/${encodeURIComponent(params.url_title)}/p/${encodeURIComponent(params.nuin)}`}
                        className="item_name" style={{color:"black",minHeight:"45px", fontWeight:"400"}}
                      >
                       {params.name}
                      </NavLink>
                     
                      {/* <NavLink
                        reloadDocument 
                      >
                        product name
                      </NavLink> */}
                      {/* <NavLink reloadDocument to={`/product_details/${props.p_id}/${props.name}`}  className="text_wrap">{props.name}</NavLink> */}
                    </div>

                    {(params.tag === "") ? <div className="Noitem-tag inter-regular">
                      <span>
                        &nbsp;</span>
                    </div> :
                    <div
                    className="item-tag inter-regular"
                    style={{
                      backgroundColor: Number(params.stock) > 0 ? "#ECFBFF" : "#E4E4E6",
                      borderRadius: "42px",
                      color: Number(params.stock) > 0 ? "#008EB0" : "#7B7B7B",
                      fontSize: "11px",
                      fontWeight: "400",
                      padding: "0px 12px",
                      marginTop: "4px",
                      display: "inline-block",
                      maxWidth: "100%", 
                      overflow: "hidden", 
                      textOverflow: "ellipsis", 
                      whiteSpace: "nowrap", 
                    }}
                  >
                    <span>{params.tag}</span>
                  </div>
                  
                    }
                     
                    <div style={{ margin: "0px" }}>

                    {params.is_subscribed=="1"?(<>
                        {Number(params.stock)>0? (<div>                        
                        <span style={{fontSize:"18px",fontWeight:"600",background: "linear-gradient(to right, #FBE4BA, #9E7829)",webkitBackgroundClip: "text", webkitTextFillColor:"transparent" }}>&nbsp; 
                          {params.elite_price}
                        
                          </span>

                      </div>):<div  className="outofstock" style={{color:"#7B7B7B",height:"52px"}}>
                      <span className="outofstock">Out of stock</span>
                      <span>Tap to view similar </span>
                        </div>
                        
                        }


                      {Number(params.stock)>0? (<div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                        
                        <span className="product-card-price" style={{fontSize:"12.5px",textDecoration:"line-through",marginLeft:'5px'}}>
                          {params.saleprice}
                          
                        </span>

                        {(Number(params.dis)>0) ? <span className="product-card-mrp" >
                          
                          <s style={{color: "#959595",}}> {params.mrp}</s>
                        </span>:"" }                        
                      </div>):<></>}
                        </>):


                      (
                        <>
                        {Number(params.stock)>0? (<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        
                        <span className="product-card-price" style={{fontSize:"18px"}}>
                          {params.saleprice}
                          
                        </span>

                        {(Number(params.dis)>0) ? <span className="product-card-mrp" >
                          
                          <s style={{color: "#959595",}}> {params.mrp}</s>
                        </span>:"" }                        
                      </div>):<></>}

                        {
                        Number(params.stock)>0? (
                        // <Row className="elite_tag" style={{margin:"0px 5px",padding:"10px 0px"}}>
                        <Row className="elite_tag" style={{margin:"0px 0px", padding:"10px 0px"}}>
                          <div style={{backgroundColor: "black",borderRadius: "16px",paddingLeft:"5px",paddingBottom:"3px", paddingTop:"3px", gap: "5px",display:"inline-flex",paddingRight:"5px"  }}>
                        <Col xs={9} sm={9} md={9} lg={9} style={{display:'flex'}}>
                        <EliteFullLogoIcon></EliteFullLogoIcon>
                        <span style={{fontSize:"15px",background: "linear-gradient(to right, #FBE4BA, #9E7829)",webkitBackgroundClip: "text", webkitTextFillColor:"transparent",fontWeight:"600" }}>&nbsp; 
                          {params.elite_price}
                        
                          </span></Col>
                          <Col className="d-flex justify-content-end inform" xs={3} sm={3} md={3} lg={3} style={{padding:"0px 10px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" style={{width:"50%",height:"100%"}} onClick={()=>gotToNewPagesss()}>
                            <path d="M5.78298 0C2.58882 0 3.13776e-08 2.58882 3.13776e-08 5.78238C-7.90826e-05 6.54183 0.149448 7.29387 0.440042 7.99553C0.730636 8.69718 1.1566 9.33473 1.69362 9.87174C2.23064 10.4088 2.86818 10.8347 3.56984 11.1253C4.2715 11.4159 5.02353 11.5654 5.78298 11.5654C8.97594 11.5654 11.5654 8.97654 11.5654 5.78238C11.5654 2.58882 8.97594 0 5.78298 0ZM6.32267 2.08768C6.88645 2.08768 7.05209 2.41475 7.05209 2.78879C7.05209 3.2556 6.67865 3.68747 6.04078 3.68747C5.50711 3.68747 5.25293 3.41943 5.26859 2.97552C5.26859 2.60147 5.5812 2.08768 6.32267 2.08768ZM4.87828 9.24578C4.49279 9.24578 4.2115 9.01148 4.48074 7.9845L4.92225 6.16245C4.99875 5.87092 5.0114 5.75407 4.92225 5.75407C4.80721 5.75407 4.30667 5.95525 4.01153 6.15402L3.81938 5.839C4.75601 5.05657 5.83298 4.59759 6.29376 4.59759C6.67925 4.59759 6.7431 5.05296 6.55095 5.75407L6.045 7.66948C5.95525 8.00799 5.9938 8.12485 6.08354 8.12485C6.19919 8.12485 6.57746 7.98511 6.9497 7.69177L7.16774 7.9845C6.25641 8.89523 5.26317 9.24578 4.87828 9.24578Z" fill="white"/>
                          </svg>
                          </Col>

                      </div>
                        </Row>
                    ):
                    <>
                    <div className="outofstock" style={{color:"#7B7B7B", height:"48px"}}>
                      <span className="outofstock">Out of stock</span>
                    
                        </div>
                        <div style={{color:'rgb(0, 142, 176)'}} onClick={() =>  params.view_similar(params.id)}>Tap to view similar  </div>
                          </>
                        }


                      
                        </>
                    )
                      }

                    </div> 
                      
                      </Container>

                        </div>
                          </div>


                        </div> 
        </>




    )
}